import Icon, { IconType } from '@atoms/Icon/Icon';
import Section from '@molecules/Section/Section';
import clsx from 'clsx';
import { useMemo } from 'react';

import styles from './EventSummary.module.scss';

interface EventItemProps {
  term: string;
  description?: string;
  icon: IconType;
}

function EventItem({ term, description, icon }: EventItemProps) {
  if (!description) return null;

  return (
    <div className={styles.group}>
      <dt className={clsx(styles.term, 'u-typography-heading-6')}>
        <Icon className={styles.icon} icon={icon} />
        {term}
      </dt>
      <dd className={styles.description}>{description}</dd>
    </div>
  );
}

interface Props {
  items: EventItemProps[];
}

export default function EventSummary({ items }: Props) {
  const [group1, group2] = useMemo(
    () =>
      items.reduce(
        (acc, cur, index) => {
          if (index < 4) {
            acc[0].push(cur);
          } else {
            acc[1].push(cur);
          }

          return acc;
        },
        [[], []] as [EventItemProps[], EventItemProps[]],
      ),
    [items],
  );

  return (
    <Section containerClassName={styles.container}>
      <div className={clsx(styles.wrapper, 'u-container-grid')}>
        {group1.length && (
          <dl className={clsx(styles.summary, 'u-mb-0')}>
            {group1.map((item, index) => (
              <EventItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                icon={item.icon}
                term={item.term}
                description={item.description}
              />
            ))}
          </dl>
        )}

        {group2.length && (
          <dl className={clsx(styles.summary, 'u-mb-0')}>
            {group2.map((item, index) => (
              <EventItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                icon={item.icon}
                term={item.term}
                description={item.description}
              />
            ))}
          </dl>
        )}
      </div>
    </Section>
  );
}
