export default function getMediaSize(): 'xs' | 'sm' | 'md' | 'lg' | 'xl' {
  if (!window) return 'xs';

  if (window.matchMedia('(min-width: 1280px)').matches) return 'xl';

  if (window.matchMedia('(min-width: 1024px)').matches) return 'lg';

  if (window.matchMedia('(min-width: 768px)').matches) return 'md';

  if (window.matchMedia('(min-width: 640px)').matches) return 'sm';

  return 'xs';
}
