import CtaButton from '@molecules/CtaButton/CtaButton';
import { Cta } from '@type-declarations/cta';
import clsx from 'clsx';

import styles from './CtaSection.module.scss';

interface Props {
  title: string;
  primaryCta?: Cta;
  secondaryCta?: Cta;
}

export default function CtaSection({ title, primaryCta, secondaryCta }: Props) {
  return (
    <section className={clsx(styles.section, 'u-container-width')}>
      <h2 className={clsx(styles.title, 'u-mb-0')}>{title}</h2>

      {(primaryCta || secondaryCta) && (
        <div className={styles.ctaContainer}>
          {primaryCta && (
            <CtaButton
              size="sm"
              variant="quinary"
              cta={primaryCta}
              className={styles.cta}
            />
          )}
          {secondaryCta && (
            <CtaButton
              size="sm"
              variant="outlined"
              cta={secondaryCta}
              className={styles.cta}
            />
          )}
        </div>
      )}
    </section>
  );
}
