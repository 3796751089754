import StructuredData from '@atoms/StructuredData/StructuredData';
import Breadcrumbs from '@molecules/Breadcrumbs/Breadcrumbs';
import VacancySpecifications from '@molecules/VacancySpecifications/VacancySpecifications';
import ApplyCta from '@organisms/ApplyCta/ApplyCta';
import ApplyCtaSection from '@organisms/ApplyCtaSection/ApplyCtaSection';
import DetailHero from '@organisms/DetailHero/DetailHero';
import Sections from '@organisms/Sections/Sections';
import type { VacancyPage as Props } from '@type-declarations/page';
import { getJobPostingStructuredData } from '@utils/getStructuredData';

export default function VacancyTemplate({ page }: { page: Props }) {
  const {
    hero,
    sections,
    department,
    company,
    minSalary,
    maxSalary,
    location,
  } = page;

  const vacancyDetails = {
    department,
    company,
    salary: { min: minSalary, max: maxSalary },
    location,
  };

  return (
    <>
      <Breadcrumbs page={page} />

      <DetailHero
        title={hero.title}
        text={hero.text}
        image={hero.image}
        focusPoint={hero.focusPoint}
      >
        <VacancySpecifications
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...vacancyDetails}
          modifier="narrow"
        />

        <ApplyCta
          vacancy={page.title}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...vacancyDetails}
        />
      </DetailHero>

      <Sections sections={sections} />

      <ApplyCtaSection title={page.applyTitle} text={page.applyText} />

      <StructuredData schema={getJobPostingStructuredData(page)} />
    </>
  );
}
