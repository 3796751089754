import {
  GENERAL_SOLUTION_ID,
  ITEMS_PER_PAGE,
  SHOW_ALL_SOLUTION_ID,
} from '@constants/constants';
import { Locale } from '@type-declarations/locale';
import { QueryParameters, SolutionsQueryType } from '@type-declarations/prepr';
import { useRouter } from 'next/router';
import { RefObject, useState } from 'react';

import useDidMountEffect from './useDidMountEffect';
import usePagination from './usePagination';

interface Props<T> {
  initItems: T[];
  initTotal: number;
  scrollToRef?: RefObject<HTMLDivElement | null>;
  fetch: (data: QueryParameters) => Promise<{ items: T[]; total: number }>;
  additionalQueryParams?: QueryParameters;
  initLimit?: number;
}

export function useOverview<T>({
  initItems,
  initTotal = 0,
  scrollToRef,
  fetch,
  additionalQueryParams,
  initLimit = ITEMS_PER_PAGE,
}: Props<T>) {
  const router = useRouter();
  const locale = router.locale as Locale;
  const [loading, setLoading] = useState(false);
  const [activeTag, setActiveTag] = useState(SHOW_ALL_SOLUTION_ID);
  const [data, setData] = useState({
    items: initItems,
    total: initTotal,
  });

  const handleFetch = async ({ page = 1 }: { page?: number }) => {
    setLoading(true);

    let solutions: SolutionsQueryType = [];
    if (
      activeTag &&
      activeTag !== GENERAL_SOLUTION_ID &&
      activeTag !== SHOW_ALL_SOLUTION_ID
    ) {
      solutions = [activeTag];
    } else if (activeTag === GENERAL_SOLUTION_ID) {
      // This returns all items that have no solutions assigned
      solutions = null;
    }

    const { items, total } = await fetch({
      page,
      solutions,
      locale,
      initLimit,
      ...additionalQueryParams,
    });

    setLoading(false);

    if (page === 1) {
      // Pagination has been reset, so we need to reset the items
      setData({
        items,
        total,
      });
    } else {
      // Pagination has not been reset, so we need to append the items
      setData({
        items: [...data.items, ...items],
        total,
      });
    }
  };

  const { hasNextPage, handleLoadMore, resetPagination } = usePagination({
    total: data.total,
    fetchMore: page => handleFetch({ page }),
    scrollToRef,
    initLimit,
  });

  const onFilterChange = async (e: React.ChangeEvent<HTMLFormElement>) => {
    const { value } = e.target;
    setActiveTag(value);
  };

  useDidMountEffect(() => {
    resetPagination();
    handleFetch({ page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTag]);

  return {
    ...data,
    loading,
    hasNextPage,
    handleLoadMore,
    onFilterChange,
    activeTag,
  };
}
