import useTranslations from '@hooks/useTranslations';
import Breadcrumbs from '@molecules/Breadcrumbs/Breadcrumbs';
import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import VacancySpecifications from '@molecules/VacancySpecifications/VacancySpecifications';
import ApplicationForm from '@organisms/ApplicationForm/ApplicationForm';
import { VacancyApplyPage as Props } from '@type-declarations/page';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export default function VacancyApplyTemplate({ page }: { page: Props }) {
  const t = useTranslations();

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY as string}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: 'head',
        nonce: undefined,
      }}
    >
      {/* TODO: Add "solliciteer" at the end */}
      <Breadcrumbs page={page} />

      <Section>
        <SectionContent>
          <h1>{t.applicationForm.title.replace('{jobTitle}', page.title)}</h1>

          <VacancySpecifications
            department={page.department}
            company={page.company}
            location={page.location}
            salary={{ min: page.minSalary, max: page.maxSalary }}
            modifier="narrow"
          />

          <ApplicationForm vacancy={page.title} company={page.company} />
        </SectionContent>
      </Section>
    </GoogleReCaptchaProvider>
  );
}
