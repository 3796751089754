import { useIsGreaterThanMd } from '@hooks/useMediaQuery';
import { LogoSlide } from '@type-declarations/slider';
import clsx from 'clsx';
import { motion, useMotionValueEvent, useScroll } from 'framer-motion';
import { useCallback, useEffect, useRef, useState } from 'react';

import ClientLogo from './ClientLogo';
import styles from './ClientsSection.module.scss';

interface Props {
  label?: string;
  clients: LogoSlide[];
}

export default function ClientsSection({ clients, label }: Props) {
  const isGreaterThanMd = useIsGreaterThanMd();
  const containerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [gridSize, setGridSize] = useState<2 | 3 | 4>(2);

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start start', 'end end'],
  });

  const handleScroll = useCallback(
    (scrollProgress: number) => {
      const getItemsPerRow = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth >= 1280) return 4;
        if (screenWidth >= 768) return 3;
        return 2;
      };

      const itemsPerRow = getItemsPerRow();
      const rowCount = Math.ceil(clients.length / gridSize);
      const stepSize = 1 / rowCount;
      const currentRowIndex = Math.ceil(scrollProgress / stepSize);

      if (gridSize !== itemsPerRow) setGridSize(itemsPerRow);

      setActiveIndex(currentRowIndex * itemsPerRow);
    },
    [clients.length, gridSize],
  );

  useMotionValueEvent(scrollYProgress, 'change', handleScroll);

  useEffect(() => {
    const handleResize = () => {
      handleScroll(scrollYProgress.get());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleScroll, scrollYProgress]);

  return (
    <section
      ref={containerRef}
      className={clsx(styles.section, 'u-container-width')}
      style={{
        height: `${Math.ceil(clients.length / gridSize) * 125}vh`,
      }}
    >
      <motion.div
        className={styles.container}
        animate={{
          y:
            ((activeIndex - gridSize) / clients.length) *
            -1 *
            24 *
            Math.ceil(clients.length / gridSize),
        }}
        transition={{
          ease: 'easeOut',
          duration: 0.5,
        }}
      >
        {label && (
          <motion.h2
            animate={
              isGreaterThanMd
                ? {
                    opacity: activeIndex > 0 ? 1 : 0,
                    y: activeIndex > 0 ? 0 : 24,
                  }
                : {
                    opacity: activeIndex > 0 ? 1 : 0,
                    y:
                      activeIndex > 0
                        ? (Math.max(activeIndex - gridSize * 2, 0) / gridSize) *
                          24
                        : 24,
                  }
            }
            className={clsx(styles.label, 'u-mb-6')}
          >
            {label}
          </motion.h2>
        )}
        <ul ref={listRef} className={clsx(styles.list, 'u-list-clean u-mb-0')}>
          {clients.map((client, index) => (
            <ClientLogo
              client={client}
              key={client.id}
              index={index}
              activeIndex={activeIndex}
              gridSize={gridSize}
              totalCount={clients.length}
            />
          ))}
        </ul>
      </motion.div>
    </section>
  );
}
