import { MAX_CONTAINER_WIDTH } from '@constants/constants';
import assetFragment from '@lib/fragments/asset-fragment';
import ctaFragment from '@lib/fragments/cta-fragment';
import gql from 'graphql-tag';

const HEIGHT = Math.ceil(MAX_CONTAINER_WIDTH * (9 / 16));

const videoSectionFragment = gql`
  fragment videoSectionFields on VideoSection {
    typename: __typename
    id: _id
    title
    text
    cta {
      ...ctaFields
    }
    video {
      youtubeId: youtube_id
      embeddedVideo: embedded_video {
        playbackId: playback_id
      }
      alt: alt_text
      thumbnail {
        ...assetFields
        url(width: ${MAX_CONTAINER_WIDTH}, height: ${HEIGHT}, format: "webp")
      }
    }
    big
    autoplay
    loop
  }
  ${assetFragment}
  ${ctaFragment}
`;

export default videoSectionFragment;
