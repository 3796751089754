import Breadcrumbs from '@molecules/Breadcrumbs/Breadcrumbs';
import DetailHero from '@organisms/DetailHero/DetailHero';
import Sections from '@organisms/Sections/Sections';
import { SolutionPage as Props } from '@type-declarations/page';

export default function SolutionTemplate({ page }: { page: Props }) {
  const { hero, sections } = page;

  return (
    <>
      <Breadcrumbs page={page} />

      <DetailHero
        title={hero.title}
        text={hero.text}
        image={hero.image}
        focusPoint={hero.focusPoint}
      />

      <Sections sections={sections} />
    </>
  );
}
