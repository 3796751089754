import { eventItem } from '@lib/fragments/overview-fragment';
import gql from 'graphql-tag';

const EVENT_PAGES_QUERY = gql`
  query EVENT_PAGES_QUERY(
    $limit: Int
    $skip: Int
    $locale: String
    $fromGte: String
    $untilLt: String
    $sort: EventPageSortInput = start_date_ASC
  ) {
    EventPages(
      limit: $limit
      skip: $skip
      locale: $locale
      sort: $sort
      where: { start_date_gte: $fromGte, start_date_lt: $untilLt }
    ) {
      items {
        ...eventItem
      }
      total
    }
  }
  ${eventItem}
`;

export default EVENT_PAGES_QUERY;
