import Button from '@atoms/Button/Button';
import { ROUTES } from '@constants/constants';
import useLocale from '@hooks/useLocale';
import useTranslations from '@hooks/useTranslations';
import Section from '@molecules/Section/Section';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import styles from './ApplyCtaSection.module.scss';

interface Props {
  title: string;
  text: string;
}

export default function ApplyCtaSection({ title, text }: Props) {
  const router = useRouter();
  const locale = useLocale();
  const t = useTranslations();

  return (
    <Section containerClassName={clsx('u-container-grid', styles.container)}>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.text}>{text}</p>

      <div className={styles.ctaWrapper}>
        <Button
          href={`${router.asPath}/${ROUTES.VacancyApplyPage[locale]}`}
          label={t.applyNow}
          stretch
        />
      </div>
    </Section>
  );
}
