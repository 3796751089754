import assetFragment from '@lib/fragments/asset-fragment';
import ctaFragment from '@lib/fragments/cta-fragment';
import gql from 'graphql-tag';

const MAX_IMAGE_SIZE_ON_LG = 903;

const textImageSectionFragment = gql`
  fragment textImageSectionFields on TextImageSection {
    typename: __typename
    id: _id
    title
    punchline
    text
    cta {
      ...ctaFields
    }
    image {
      ...assetFields
      url(width: ${MAX_IMAGE_SIZE_ON_LG}, format: "webp")
    }
    imageAlt: alt_text
  }
  ${assetFragment}
  ${ctaFragment}
`;

export default textImageSectionFragment;
