import { ctaLinkFields } from './cta-fragment';

export const contentSlideFields = `
  typename: __typename
  id: _id
  title
  text
  link {
    ${ctaLinkFields}
  }
`;
