import { fetchPrepr } from '@lib/prepr/prepr';
import NEWS_OVERVIEW_PAGE_QUERY from '@lib/queries/newsOverviewPage';
import NEWS_PAGE_QUERY from '@lib/queries/newsPage';
import NEWS_PAGES_QUERY from '@lib/queries/newsPages';
import SOLUTIONS_FILTER_QUERY from '@lib/queries/solutionsFilter';
import { SolutionsFilterResponse } from '@type-declarations/filter';
import { News } from '@type-declarations/overviewItems';
import { NewsOverviewPage, NewsPage } from '@type-declarations/page';
import { QueryParameters } from '@type-declarations/prepr';

export interface NewsOverviewPageResponse {
  data: {
    NewsOverviewPage: NewsOverviewPage;
  };
}

export interface NewsPagesResponse {
  data: {
    NewsPages: {
      items: News[];
      total: number;
    };
  };
}

export interface NewsPageResponse {
  data: {
    NewsPage: NewsPage;
  };
}

/*
  News overviewpage
*/
export async function fetchNewsOverviewPage({
  locale,
  preview,
  page = 1,
}: QueryParameters) {
  const [newsOverviewPage, newsPages, solutionsFilter] = await Promise.all([
    fetchPrepr<NewsOverviewPageResponse>({
      query: NEWS_OVERVIEW_PAGE_QUERY,
      variables: { locale, preview },
    }),
    fetchPrepr<NewsPagesResponse>({
      query: NEWS_PAGES_QUERY(),
      variables: {
        page,
        locale,
        preview,
      },
    }),
    fetchPrepr<SolutionsFilterResponse>({
      query: SOLUTIONS_FILTER_QUERY,
      variables: {
        locale,
        preview,
      },
    }),
  ]);

  return {
    ...newsOverviewPage?.data?.NewsOverviewPage,
    items: newsPages?.data?.NewsPages.items || [],
    total: newsPages?.data?.NewsPages.total || 0,
    tags: solutionsFilter?.data?.SolutionPages?.items || [],
  };
}

export async function fetchNewsPage({
  slug,
  locale,
  preview,
}: QueryParameters) {
  if (!slug?.length) return {};
  const res = await fetchPrepr<NewsPageResponse>({
    query: NEWS_PAGE_QUERY,
    variables: { slug: slug[1], locale, preview },
  });
  return res?.data?.NewsPage;
}

/* Event pages */
export async function fetchNewsPages({
  page,
  locale,
  preview,
  solutions,
}: QueryParameters) {
  const res = await fetchPrepr<NewsPagesResponse>({
    query: NEWS_PAGES_QUERY(solutions),
    variables: { page, locale, preview, solutions },
  });

  return {
    items: res?.data?.NewsPages.items || [],
    total: res?.data?.NewsPages.total || 0,
  };
}
