import { ITEMS_PER_PAGE } from '@constants/constants';
import { useRouter } from 'next/router';
import { MutableRefObject, useEffect, useState } from 'react';

const SCROLL_OFFSET = 64;

interface Props {
  total: number;
  limit?: number;
  initLimit?: number;
  fetchMore: (page: number) => void;
  scrollToRef?: MutableRefObject<HTMLDivElement | null>;
}

export default function usePagination({
  total,
  limit = ITEMS_PER_PAGE,
  initLimit = limit,
  fetchMore,
  scrollToRef,
}: Props) {
  const { query } = useRouter();
  const [currentPage, setCurrentPage] = useState(Number(query.page) || 1);
  const totalPages = Math.ceil(Math.max(total - initLimit, 0) / limit) + 1;
  const hasNextPage = !!totalPages && totalPages !== currentPage;

  const scrollTo = () => {
    window.scrollTo({
      top: (scrollToRef?.current?.offsetTop || 0) - SCROLL_OFFSET,
      behavior: 'smooth',
    });
  };

  const handleLoadMore = () => {
    const updatedCurrentPage = currentPage + 1;
    setCurrentPage(updatedCurrentPage);
    fetchMore(updatedCurrentPage);
    if (scrollToRef) {
      scrollTo();
    }
  };

  // Reset internal pagination
  const resetPagination = () => {
    setCurrentPage(1);
  };

  useEffect(() => {
    if (currentPage !== Number(query.page)) {
      resetPagination();
    }
    // eslint-disable-next-line
  }, [query.page]);

  return {
    currentPage,
    totalPages,
    hasNextPage,
    handleLoadMore,
    resetPagination,
  };
}
