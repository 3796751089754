import useLocale from '@hooks/useLocale';
import { useIsGreaterThanLg } from '@hooks/useMediaQuery';
import { Service } from '@type-declarations/brand';
import getInternalRoute from '@utils/getInternalRoute';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';

import styles from './ServicesSection.module.scss';

interface Props {
  label: string;
  services?: Service[];
}

export default function ServicesSection({ label, services }: Props) {
  const [hoveredId, setHoveredId] = useState<string | null>(null);
  const locale = useLocale();
  const isGreaterThanLg = useIsGreaterThanLg();

  const handleMouseEnter = (id: string) => {
    if (!isGreaterThanLg) return;
    setHoveredId(id);
  };

  const handleMouseLeave = () => {
    if (!isGreaterThanLg) return;
    setHoveredId(null);
  };

  const servicesWithLink = services?.filter(
    service => !!service.link.internalLink?.page.length,
  );

  return (
    <section className={styles.section}>
      <div className={clsx(styles.container, 'u-container-width')}>
        <h2 className={clsx(styles.label, 'u-mb-0')}>{label}</h2>

        <ul className={clsx(styles.list, 'u-list-clean u-mb-0')}>
          {servicesWithLink?.map(({ id, image, link }) => (
            <li
              onMouseEnter={() => handleMouseEnter(id)}
              onMouseLeave={handleMouseLeave}
              className={styles.item}
              key={id}
            >
              <Link
                href={getInternalRoute({
                  internalLink: link.internalLink!,
                  locale,
                })}
                className={styles.itemLabel}
              >
                <span className={styles.title}>{link.title}</span>

                <div className={styles.imageContainer}>
                  <AnimatePresence>
                    {hoveredId === id && (
                      <motion.div
                        layoutId="brand-services-image"
                        className={styles.image}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        <Image
                          src={image.url}
                          placeholder="blur"
                          blurDataURL={image.placeholder}
                          alt=""
                          width={550} // Prepr 'Square' preset
                          height={550} // Prepr 'Square' preset
                        />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
