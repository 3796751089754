import { ITEMS_PER_PAGE_PAST_EVENTS } from '@constants/constants';
import EVENT_PAGE_QUERY from '@lib/queries/eventPage';
import EVENT_PAGES_QUERY from '@lib/queries/eventPages';
import EVENTS_OVERVIEW_PAGE_QUERY from '@lib/queries/eventsOverviewPage';
import { Events } from '@type-declarations/overviewItems';
import { EventPage, EventsOverviewPage } from '@type-declarations/page';
import { QueryParameters } from '@type-declarations/prepr';

import { fetchPrepr } from './prepr';

export interface EventsOverviewPageResponse {
  data: {
    EventsOverviewPage: EventsOverviewPage;
  };
}

export interface EventPagesResponse {
  data: {
    EventPages: {
      items: Events[];
      total: number;
    };
  };
}

export interface EventPageResponse {
  data: {
    EventPage: EventPage;
  };
}

export async function fetchEventsOverviewPage({
  locale,
  preview,
  page = 1,
}: QueryParameters) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  today.setMinutes(-today.getTimezoneOffset());
  const todayISO = today.toISOString();

  const [eventsOverviewPage, upcomingEvents, pastEvents] = await Promise.all([
    fetchPrepr<EventsOverviewPageResponse>({
      query: EVENTS_OVERVIEW_PAGE_QUERY,
      variables: { locale, preview },
    }),
    fetchPrepr<EventPagesResponse>({
      query: EVENT_PAGES_QUERY,
      variables: {
        page,
        locale,
        preview,
        fromGte: todayISO,
      },
    }),
    fetchPrepr<EventPagesResponse>({
      query: EVENT_PAGES_QUERY,
      variables: {
        page,
        limit: ITEMS_PER_PAGE_PAST_EVENTS,
        locale,
        preview,
        untilLt: todayISO,
        sort: 'start_date_DESC',
      },
    }),
  ]);

  return {
    ...eventsOverviewPage?.data?.EventsOverviewPage,
    pastEvents: pastEvents?.data?.EventPages.items || [],
    pastEventsTotal: pastEvents?.data?.EventPages.total || 0,
    upcomingEvents: upcomingEvents?.data?.EventPages.items || [],
    upcomingEventsTotal: upcomingEvents?.data?.EventPages.total || 0,
  };
}

export async function fetchEventPage({
  slug,
  locale,
  preview,
}: QueryParameters) {
  if (!slug?.length) return {};
  const res = await fetchPrepr<EventPageResponse>({
    query: EVENT_PAGE_QUERY,
    variables: { slug: slug[1], locale, preview },
  });
  return res?.data?.EventPage;
}

export async function fetchEventsPages({
  page,
  locale,
  preview,
  fromGte,
  untilLt,
  limit,
  initLimit,
}: QueryParameters) {
  const res = await fetchPrepr<EventPagesResponse>({
    query: EVENT_PAGES_QUERY,
    variables: {
      page,
      locale,
      preview,
      fromGte,
      untilLt,
      limit,
      initLimit,
    },
  });

  return {
    items: res?.data?.EventPages.items || [],
    total: res?.data?.EventPages.total || 0,
  };
}
