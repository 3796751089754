import Button from '@atoms/Button/Button';
import ErrorMessage from '@atoms/ErrorMessage/ErrorMessage';
import StructuredData from '@atoms/StructuredData/StructuredData';
import useLocale from '@hooks/useLocale';
import useTranslations from '@hooks/useTranslations';
import Breadcrumbs from '@molecules/Breadcrumbs/Breadcrumbs';
import DetailHero from '@organisms/DetailHero/DetailHero';
import EventSummary from '@organisms/EventSummary/EventSummary';
import IframeSection from '@organisms/IframeSection/IframeSection';
import Sections from '@organisms/Sections/Sections';
import TextSection from '@organisms/TextSection/TextSection';
import { EventPage as Props } from '@type-declarations/page';
import capitalizeFirstLetter from '@utils/capitalizeFirstLetter';
import formatDate from '@utils/formatDate';
import formatLocation from '@utils/formatLocation';
import { getEventStructuredData } from '@utils/getStructuredData';
import { isPast } from 'date-fns';
import { useMemo } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

const EVENT_FORM_ID = 'event-form-id';

export default function EventTemplate({ page }: { page: Props }) {
  const {
    hero,
    iframeSource,
    iframeName,
    iframeHeight,
    iframeTitle,
    iframePunchline,
    iframeText,
    language,
    startDate,
    endDate,
    sections,
    location,
    price,
    targetAudience,
  } = page;
  const t = useTranslations();
  const locale = useLocale();

  const date = capitalizeFirstLetter(
    formatDate({
      date: startDate,
      format: 'EEEE d MMMM y',
      locale,
    }),
  );
  const eventIsInPast = isPast(startDate);

  const time = useMemo(
    () =>
      `${formatDate({
        date: startDate,
        format: 'HH:mm',
        locale,
      })} - ${formatDate({ date: endDate, format: 'HH:mm', locale })}`,
    [startDate, endDate, locale],
  );

  const formattedLocation = useMemo(() => formatLocation(location), [location]);

  return (
    <>
      <Breadcrumbs page={page} />

      <DetailHero
        title={hero.title}
        text={hero.text}
        image={hero.image}
        focusPoint={hero.focusPoint}
      >
        {iframeSource && !eventIsInPast && (
          <Button
            label={t.signUp}
            href={`#${EVENT_FORM_ID}`}
            variant="secondary"
          />
        )}
        {eventIsInPast && <ErrorMessage message={t.eventIsInPast} />}
      </DetailHero>

      <TextSection
        noPadding
        id="event-general-info"
        typename="TextSection"
        text={renderToStaticMarkup(
          <>
            <p>
              <span className="u-typography-heading-6">{t.date}</span>
              <br />
              {date}
            </p>
            <p>
              <span className="u-typography-heading-6">{t.location}</span>
              <br />
              <span className="u-white-space-pre-line">
                {formattedLocation || t.online}
              </span>
            </p>
            <hr />
          </>,
        )}
      />

      <Sections sections={sections} />

      <EventSummary
        items={[
          { term: t.date, description: date, icon: 'calendar' },
          { term: t.time, description: time, icon: 'calendar' },
          { term: t.language, description: language, icon: 'calendar' },
          {
            term: t.location,
            description: formattedLocation || t.online,
            icon: 'calendar',
          },
          {
            term: t.targetAudience,
            description: targetAudience,
            icon: 'calendar',
          },
          { term: t.costs, description: price, icon: 'calendar' },
        ]}
      />

      {iframeSource && !eventIsInPast && (
        <IframeSection
          id={EVENT_FORM_ID}
          typename="IframeSection"
          iframeSource={iframeSource}
          iframeName={iframeName}
          iframeHeight={iframeHeight}
          title={iframeTitle}
          punchline={iframePunchline}
          text={iframeText}
        />
      )}

      <StructuredData schema={getEventStructuredData(page, locale)} />
    </>
  );
}
