import Button from '@atoms/Button/Button';
import useTranslations from '@hooks/useTranslations';
import VacancySpecifications from '@molecules/VacancySpecifications/VacancySpecifications';
import { Salary } from '@type-declarations/vacancy';
import clsx from 'clsx';

import styles from './VacancyCard.module.scss';

interface Props {
  title: string;
  text: string;
  department: string;
  company: string;
  location: string;
  salary: Salary;
  href: string;
}

export default function VacancyCard({
  title,
  text,
  department,
  company,
  location,
  salary,
  href,
}: Props) {
  const t = useTranslations();

  return (
    <article className={styles.card}>
      <div className={styles.content}>
        <h1 className={clsx(styles.title, 'u-typography-heading-3')}>
          {title}
        </h1>

        <p className={styles.description}>{text}</p>

        <VacancySpecifications
          department={department}
          company={company}
          salary={salary}
          location={location}
          className={styles.specifications}
        />
      </div>

      <Button
        href={href}
        label={t.viewVacancy}
        variant="secondary"
        className={clsx(styles.cta, 'u-umbrella-link')}
      >
        {t.viewVacancy}
      </Button>
    </article>
  );
}
