import ChevronDown from '@svg/chevron-down.svg';
import clsx from 'clsx';
import { motion, MotionValue, useTransform } from 'framer-motion';
import { RefObject, useEffect, useRef } from 'react';

import styles from './HeroSection.module.scss';

interface Props {
  video: {
    highRes: string;
  };
  heroRef: RefObject<HTMLDivElement>;
  inView: boolean;
  title: string;
  scrollYProgress: MotionValue<number>;
  hasScrolled: boolean;
}

export default function HeroSection({
  video,
  heroRef,
  inView,
  title,
  scrollYProgress,
  hasScrolled,
}: Props) {
  const videoRef = useRef<HTMLVideoElement>(null);

  /* Play/pause video on inView */
  useEffect(() => {
    if (inView) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  }, [inView]);

  const y = useTransform(scrollYProgress, [0, 1], [0, 200]); // same as css variable: --stories-overlap: 200px;

  return (
    <motion.section
      style={{ y }}
      data-brand-hero
      ref={heroRef}
      className={styles.section}
    >
      <h1 className="u-visually-hidden">{title}</h1>
      <video
        data-hero-video
        ref={videoRef}
        className={styles.video}
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={video.highRes} type="video/mp4" />
      </video>

      <div className={clsx(styles.scroll, hasScrolled && styles.hidden)}>
        <ChevronDown className={styles.chevron} />
      </div>
    </motion.section>
  );
}
