import { Error } from '@type-declarations/error';
import { useState } from 'react';

export function useFormErrors() {
  const [errors, setErrors] = useState<Error[]>([]);

  const getFirstError = (key: string) =>
    errors.find(error => error.key === key);

  const clearErrors = (key: string) => {
    setErrors(errors.filter(error => error.key !== key));
  };

  return { errors, setErrors, getFirstError, clearErrors };
}
