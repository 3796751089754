import StructuredData from '@atoms/StructuredData/StructuredData';
import Breadcrumbs from '@molecules/Breadcrumbs/Breadcrumbs';
import ContentHero from '@organisms/ContentHero/ContentHero';
import Sections from '@organisms/Sections/Sections';
import { NewsPage as Props } from '@type-declarations/page';
import { getArticleStructuredData } from '@utils/getStructuredData';

function NewsTemplate({ page }: { page: Props }) {
  const { title, publishOn, hero, sections } = page;

  return (
    <>
      <Breadcrumbs page={page} />

      <ContentHero
        shortTitle={title}
        longTitle={hero?.title}
        text={hero?.text}
        image={hero?.image}
        focusPoint={hero?.focusPoint}
        cta={hero?.cta}
        date={publishOn}
      />

      <Sections sections={sections} />

      <StructuredData schema={getArticleStructuredData(page)} />
    </>
  );
}

export default NewsTemplate;
