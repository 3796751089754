import { DEFAULT_LOCALE, ITEMS_PER_PAGE, LOCALES } from '@constants/constants';
import type { ErrorCollection, Variables } from '@preprio/nodejs-sdk';
import { createPreprClient } from '@preprio/nodejs-sdk';
import { Locale } from '@type-declarations/locale';
import { clamp } from '@utils/clamp';
import { ASTNode, print } from 'graphql';

interface FetchPreprParameters {
  query: ASTNode;
  variables?: Variables;
}

interface FetchAllPreprParameters<T, U> extends FetchPreprParameters {
  totalQuery: ASTNode;
  itemsAccessor: (data?: T) => U[] | undefined;
  totalAccessor: (data?: T) => number | undefined;
}

function isError(data: ErrorCollection | unknown): data is ErrorCollection {
  return (data as ErrorCollection).errors !== undefined;
}

export const preprClient = createPreprClient({
  token: process.env.PREPR_ACCESS_TOKEN as string, // You can find one in your Prepr environment
  baseUrl: 'https://graphql.prepr.io/graphql',
  timeout: 30000,
});

export async function fetchPrepr<T>({
  query,
  variables = {},
}: FetchPreprParameters) {
  const {
    preview,
    locale = DEFAULT_LOCALE,
    limit = ITEMS_PER_PAGE,
    page = 1,
    customerId,
    initLimit = limit,
    ...graphqlVariables
  } = variables;

  const typedLocale = locale as Locale;
  const skip = (page - 2) * limit + initLimit; // initLimit is used for the case when the first amount of items differs from the pagination amount (past events, for example)
  const preprToken = preview
    ? process.env.PREPR_PREVIEW_ACCESS_TOKEN
    : process.env.PREPR_ACCESS_TOKEN;

  return preprClient
    .graphqlQuery(print(query))
    .graphqlVariables({
      ...graphqlVariables,
      preview,
      limit,
      skip,
      locale: LOCALES[typedLocale],
    })
    .token(preprToken)
    .customerId(customerId)
    .fetch<T>()
    .then(res => {
      if (isError(res)) {
        console.error(res);
        throw new Error(res.errors[0].message);
      }
      return res;
    })
    .catch((error: Error) => {
      console.error('[fetchPrepr error]', { error });
      throw new Error(error.message);
    });
}

const minimumChunkSize = 25;
const maximumChunkSize = 100;
const defaultAmountOfAsyncRequests = 25;

/**
 * Get the limit for the amount of items to fetch per request
 *
 * Example: Prepr has 1500 items. The default amount of async requests is 25. The limit will be 50.
 * It has a minimum of 25 and a maximum of 100.
 */
const calculateLimit = (total: number) =>
  clamp(
    minimumChunkSize,
    Math.ceil(total / defaultAmountOfAsyncRequests),
    maximumChunkSize,
  );

export async function fetchAllPrepr<T, U>({
  totalQuery,
  query,
  variables = {},
  itemsAccessor,
  totalAccessor,
}: FetchAllPreprParameters<T, U>) {
  // Get the total amount of items
  const res = await fetchPrepr<T>({
    query: totalQuery,
    variables: {
      ...variables,
    },
  });

  if (!res) return { items: [], total: 0 };

  const total = totalAccessor(res);
  if (!total) return { items: [], total: 0 };

  const limit = calculateLimit(total);
  const pages = Math.ceil(total / limit);

  // Chunk the requests and store them in an array
  const promises = Array.from({ length: pages }, (_, i) =>
    fetchPrepr<T>({
      query,
      variables: {
        ...variables,
        limit,
        page: i + 1,
      },
    }),
  );

  // Make all requests asynchronously
  const results = await Promise.all(promises);

  // Combine all items into one array
  const items = results.reduce(
    (acc, result) => {
      if (!result) return acc;
      return acc.concat(itemsAccessor(result) || []);
    },
    itemsAccessor(res) || [],
  );

  return { items, total };
}
