import gql from 'graphql-tag';

const assetFragment = gql`
  fragment assetFields on Asset {
    id: _id
    width
    height
    caption
    placeholder: url(width: 8)
    description
  }
`;

export default assetFragment;
