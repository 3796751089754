import {
  GENERAL_SOLUTION_ID,
  SHOW_ALL_SOLUTION_ID,
} from '@constants/constants';
import useTranslations from '@hooks/useTranslations';
import Section from '@molecules/Section/Section';
import { FilterItem } from '@type-declarations/filter';
import clsx from 'clsx';
import { ChangeEvent } from 'react';

import FilterOption from './FilterOption';
import styles from './TagsFilter.module.scss';

interface TagsFilterProps {
  tags: FilterItem[];
  onChange: (e: ChangeEvent<HTMLFormElement>) => void;
  total: number;
  activeTag?: string;
  generalFilterLabel?: string;
}

export default function TagsFilter({
  tags,
  activeTag,
  onChange,
  total,
  generalFilterLabel,
}: TagsFilterProps) {
  const t = useTranslations();
  const allTags = [
    { id: SHOW_ALL_SOLUTION_ID, title: t.tagsFilter.showAll },
    generalFilterLabel
      ? {
          id: GENERAL_SOLUTION_ID,
          title: generalFilterLabel,
        }
      : null,
    ...tags,
  ].filter(Boolean) as FilterItem[];
  const halfLength = Math.ceil(allTags.length / 2);
  const splitTags = [allTags.slice(0, halfLength), allTags.slice(halfLength)];

  return (
    <Section noPadding containerClassName={styles.filterContainer}>
      <h3 className={clsx(styles.title, 'u-color-blue-900 u-mb-0')}>
        {total === 0 && t.noResults}
        {total === 1 && t.oneResult}
        {total > 1 && t.allXResults.replace('{total}', total.toString())}
      </h3>

      <form
        onSubmit={e => {
          e.preventDefault();
        }}
        onChange={onChange}
        className={styles.form}
      >
        {splitTags.map((half, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className={styles.row}>
            {half.map(({ id, title }) => (
              <FilterOption
                key={id}
                id={id}
                title={title}
                activeTag={activeTag}
              />
            ))}
          </div>
        ))}
      </form>
    </Section>
  );
}
