import Button from '@atoms/Button/Button';
import useTranslations from '@hooks/useTranslations';
import { Image as ImageProps } from '@type-declarations/media';
import clsx from 'clsx';
import Image from 'next/legacy/image';

import styles from './CompanyItem.module.scss';

interface Props {
  title: string;
  logo: ImageProps;
  overviewInfo: {
    text: string;
    image: ImageProps;
  };
  href: string;
  mirrored?: boolean;
}

export default function CompanyItem({
  title,
  logo,
  overviewInfo,
  href,
  mirrored,
}: Props) {
  const t = useTranslations();
  const { image, text } = overviewInfo;

  return (
    <article className={clsx(styles.item, mirrored && styles.mirrored)}>
      <div className={styles.content}>
        <h1 className="u-visually-hidden">{title}</h1>

        <div className={styles.logoContainer}>
          <Image
            src={logo.url}
            placeholder="blur"
            blurDataURL={logo.placeholder}
            sizes="400px"
            alt={logo.description || ''}
            layout="fill"
            objectFit="contain"
            className={styles.logo}
            quality={100}
          />
        </div>

        <p className={styles.text}>{text}</p>

        <Button
          label={t.viewCompany}
          href={href}
          variant="secondary"
          className={clsx(styles.cta, 'u-umbrella-link')}
        />

        <span className={styles.cutoutTopLeft} aria-hidden />
        <span className={styles.cutoutTopRight} aria-hidden />
        <span className={styles.cutoutBottomLeft} aria-hidden />
        <span className={styles.cutoutBottomRight} aria-hidden />
      </div>

      <div className={styles.imageContainer}>
        <Image
          src={image.url}
          placeholder="blur"
          blurDataURL={image.placeholder}
          sizes="325px"
          alt={image.description || ''}
          objectFit="cover"
          layout="fill"
          className={styles.image}
          quality={100}
        />
      </div>
    </article>
  );
}
