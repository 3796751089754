import Matter, {
  Body,
  Composite,
  Constraint,
  Events,
  IEngineDefinition,
  IRendererOptions,
  MouseConstraint,
  World,
} from 'matter-js';
import { useCallback, useEffect, useRef } from 'react';

interface Props {
  renderOptions?: IRendererOptions;
  engineOptions?: IEngineDefinition;
}

export default function useMatterjs({
  renderOptions,
  engineOptions = {},
}: Props) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const engine = useRef<Matter.Engine | null>(null);
  const render = useRef<Matter.Render | null>(null);
  const runner = useRef<Matter.Runner | null>(null);

  useEffect(() => {
    engine.current = Matter.Engine.create(engineOptions);

    const containerWidth = canvasRef.current?.parentElement?.clientWidth || 400;
    const containerHeight =
      canvasRef.current?.parentElement?.clientHeight || 400;

    render.current = Matter.Render.create({
      canvas: canvasRef.current!,
      engine: engine.current,
      options: {
        width: containerWidth,
        height: containerHeight,
        wireframes: false,
        background: '#fff',
        ...renderOptions,
      },
    });

    return () => {
      // Clear the engine
      if (engine.current) {
        Matter.Engine.clear(engine.current);
      }

      // Stop the render
      if (render.current) {
        Matter.Render.stop(render.current);
      }

      // Stop the runner
      if (runner.current) {
        Matter.Runner.stop(runner.current);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startWorld = useCallback(
    (bodies: (Body | Constraint | MouseConstraint)[] = []) => {
      if (!engine.current || !render.current) return;

      // Reset the world
      Composite.clear(engine.current.world, false);

      // Add the bodies
      Composite.add(engine.current.world, bodies);

      // Create a runner and run the engine
      if (runner.current) {
        Matter.Runner.stop(runner.current);
      }

      runner.current = Matter.Runner.create();
      Matter.Runner.run(runner.current, engine.current);
      Matter.Render.run(render.current);
    },
    [],
  );

  const endWorld = useCallback(() => {
    if (!engine.current || !render.current) return;

    // Clear the engine
    Matter.Engine.clear(engine.current);

    // Stop the render
    Matter.Render.stop(render.current);

    // Stop the runner
    if (runner.current) {
      Matter.Runner.stop(runner.current);
    }
  }, []);

  // Add bodies
  const addToWorld = useCallback(
    (bodies: (Body | Constraint | MouseConstraint)[]) => {
      if (!engine.current) return;

      World.add(engine.current.world, bodies);
    },
    [],
  );

  // Remove bodies
  const removeFromWorld = useCallback(
    (bodies: (Body | Constraint | MouseConstraint)[]) => {
      if (!engine.current) return;

      World.remove(engine.current.world, bodies);
    },
    [],
  );

  // Add event listeners
  const addEngineEventListener = useCallback(
    (event: string, callback: () => void) => {
      if (!engine.current) return;

      Events.on(engine.current, event, callback);
    },
    [],
  );

  const removeEngineEventListener = useCallback(
    (event: string, callback: () => void) => {
      if (!engine.current) return;

      Events.off(engine.current, event, callback);
    },
    [],
  );

  return {
    canvasRef,
    engine,
    startWorld,
    endWorld,
    addToWorld,
    removeFromWorld,
    addEngineEventListener,
    removeEngineEventListener,
  };
}
