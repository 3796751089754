import gql from 'graphql-tag';

import assetFragment from '../asset-fragment';

const quoteSectionFragment = gql`
  fragment quoteSectionFields on QuoteSection {
    typename: __typename
    id: _id
    quote
    image {
      ...assetFields
      url(preset: "Author", format: "webp")
    }
    authorName: author_name
    authorRole: author_role
  }
  ${assetFragment}
`;

export default quoteSectionFragment;
