import gql from 'graphql-tag';

import assetFragment from '../asset-fragment';
import { overviewInfoFragment } from '../overview-fragment';

const downloadSectionFragment = gql`
  fragment downloadSectionFields on DownloadSection {
    typename: __typename
    id: _id
    title
    punchline
    text
    pages {
      id: _id
      title
      slug: _slug
      overviewInfo: overview_info {
        ...overviewInfoFields
        type
      }
    }
    cta {
      ...ctaFields
    }
  }
  ${assetFragment}
  ${overviewInfoFragment}
`;

export default downloadSectionFragment;
