/* eslint-disable jsx-a11y/label-has-associated-control */
import clsx from 'clsx';

import styles from './FilterOption.module.scss';

interface FilterOptionProps {
  id: string;
  title: string;
  disabled?: boolean;
  activeTag?: string;
}

function FilterOption({ id, title, disabled, activeTag }: FilterOptionProps) {
  return (
    <label>
      <input
        type="radio"
        name="tag-filter"
        value={id}
        disabled={disabled}
        defaultChecked={id === activeTag}
        className={clsx(styles.input, 'u-visually-hidden')}
      />
      <span className={styles.button}>
        <span className={styles.label}>{title}</span>
      </span>
    </label>
  );
}

export default FilterOption;
