import gql from 'graphql-tag';

import { contentSlideFields } from '../content-slide-fragment';

const contentGridSectionFragment = gql`
  fragment contentGridSectionFields on ContentGridSection {
    typename: __typename
    id: _id
    title
    text
    cta {
      ...ctaFields
    }
    content {
      ${contentSlideFields}
    }
  }
`;

export default contentGridSectionFragment;
