import { Location } from '@type-declarations/location';

export default function formatLocation(location?: Location) {
  if (!location) return '';

  const { title, street, zipcode, city, country } = location;

  let address = '';

  if (title) {
    address += `${title}\n`;
  }

  if (street) {
    address += `${street} -`;
  }

  if (zipcode) {
    address += ` ${zipcode}`;
  }

  if (city) {
    address += ` ${city}`;
  }

  if (country) {
    address += `\n${country}`;
  }

  return address;
}
