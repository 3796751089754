import { useIsGreaterThanMd } from '@hooks/useMediaQuery';
import useTranslations from '@hooks/useTranslations';
import { LogoSlide } from '@type-declarations/slider';
import { motion } from 'framer-motion';
import Image from 'next/legacy/image';
import { useEffect, useState } from 'react';

import styles from './ClientsSection.module.scss';

interface Props {
  client: LogoSlide;
  index: number;
  activeIndex: number;
  gridSize: 2 | 3 | 4;
  totalCount: number;
}

const variants = {
  active: {
    opacity: 1,
    y: 0,
  },
  uncovered: {
    opacity: 1,
    y: 0,
  },
  passed: {
    opacity: 0.2,
    y: 0,
  },
  // same as 'passed' on desktop
  hiddenOnMobile: {
    opacity: 0.2,
    y: 0,
  },
  inactive: {
    opacity: 0,
    y: '50vh',
  },
};

export default function ClientLogo({
  client: { id, image, title },
  index,
  activeIndex,
  gridSize,
  totalCount,
}: Props) {
  const t = useTranslations();
  const isGreaterThanMd = useIsGreaterThanMd();

  const [animationState, setAnimationState] = useState<
    'active' | 'passed' | 'hiddenOnMobile' | 'uncovered' | 'inactive'
  >('inactive');

  useEffect(() => {
    const gridDifference = totalCount % gridSize;

    if (index >= activeIndex - gridSize && index < activeIndex) {
      setAnimationState('active');
    } else if (
      gridDifference > 0 &&
      activeIndex > totalCount &&
      index >= totalCount + 1 - gridSize - gridDifference
    ) {
      setAnimationState('uncovered');
    } else if (index < activeIndex - 2 * gridSize) {
      setAnimationState('hiddenOnMobile');
    } else if (index < activeIndex) {
      setAnimationState('passed');
    } else {
      setAnimationState('inactive');
    }
  }, [activeIndex, gridSize, index, totalCount]);

  return (
    <motion.li
      variants={
        isGreaterThanMd
          ? variants
          : { ...variants, hiddenOnMobile: { opacity: 0, y: 0 } }
      }
      transition={{
        delay: 0.05 * (index - activeIndex),
        ease: 'easeOut',
        duration: 0.5,
      }}
      initial="inactive"
      animate={animationState}
      key={id}
      className={styles.item}
    >
      <div className={styles.imageWrapper}>
        <Image
          src={image.url}
          placeholder="blur"
          blurDataURL={image.placeholder}
          width={400}
          height={400}
          alt={title || t.clientLogo}
        />
      </div>
    </motion.li>
  );
}
