import gql from 'graphql-tag';

import {
  caseItem,
  contentItem,
  newsItem,
  solutionItem,
} from '../overview-fragment';

const relatedPagesSectionFragment = gql`
  fragment relatedPagesSectionFields on RelatedPagesSection {
    typename: __typename
    id: _id
    title
    punchline
    text
    cta {
      ...ctaFields
    }
    selection {
      ... on Model {
        typename: __typename
        id: _id
      }

      ...caseItem
      ...contentItem
      ...newsItem
      ...solutionItem
    }
  }
  ${caseItem}
  ${contentItem}
  ${newsItem}
  ${solutionItem}
`;

export default relatedPagesSectionFragment;
