import { MAX_CONTAINER_WIDTH } from '@constants/constants';
import assetFragment from '@lib/fragments/asset-fragment';
import ctaFragment from '@lib/fragments/cta-fragment';
import gql from 'graphql-tag';

const imageSectionFragment = gql`
  fragment imageSectionFields on ImageSection {
    typename: __typename
    id: _id
    title
    text
    cta {
      ...ctaFields
    }
    image {
      ...assetFields
      url(width: ${MAX_CONTAINER_WIDTH}, format: "webp")
    }
    imageAlt: alt_text
    big
  }
  ${assetFragment}
  ${ctaFragment}
`;

export default imageSectionFragment;
