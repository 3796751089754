import gql from 'graphql-tag';

export const ctaLinkFields = `
  ... on Component {
    typename: __typename
    id: _id

    ... on InternalLink {
      title
      internalLink: internal_link {
        page {
          ... on Model {
            typename: __typename
            id: _id
            slug: _slug
          }

          ... on ContentPage {
            parent {
              ... on SolutionPage {
                id: _id
                typename: __typename
                slug: _slug
                title
              }
            }
          }
        }
      }
    }

    ... on ExternalLink {
      title
      externalLink: external_link
    }

    ... on FileLink {
      title
      file {
        url
      }
    }
  }
`;

const ctaFragment = gql`
  fragment ctaFields on Cta {
    typename: __typename
    id: _id
    link {
      ${ctaLinkFields}
    }
  }
`;

export default ctaFragment;
