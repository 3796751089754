import gql from 'graphql-tag';

import assetFragment from '../asset-fragment';

const pageWithSolution = `
  title
  solutions {
    id: _id
    title
  }`;

const cardsSectionFragment = gql`
  fragment cardsSectionFields on CardsSection {
    typename: __typename
    id: _id
    cards {
      id: _id
      image {
        ...assetFields
        url(width: 1416, format: "webp")
      }
      focusPoint: focus_point
      page {
        ... on Model {
          typename: __typename
          id: _id
          slug: _slug
        }
        ... on CasePage {
          ${pageWithSolution}
        }
        ... on NewsPage {
          ${pageWithSolution}
        }
        ... on CompanyPage {
          ${pageWithSolution}
        }
        ... on ContentPage {
          ${pageWithSolution}
        }
        ... on EventPage {
          title
        }
        ... on SolutionPage {
          title
        }
        ... on VacancyPage {
          title
        }
      }
    }
  }
  ${assetFragment}
`;

export default cardsSectionFragment;
