import ContentHero from '@organisms/ContentHero/ContentHero';
import Sections from '@organisms/Sections/Sections';
import { ThankYouPage } from '@type-declarations/page';

export default function ThankYouTemplate({
  page: { title, hero, sections },
}: {
  page: ThankYouPage;
}) {
  return (
    <>
      <ContentHero
        shortTitle={title}
        longTitle={hero?.title}
        text={hero?.text}
        image={hero?.image}
        focusPoint={hero?.focusPoint}
        cta={hero?.cta}
      />

      <Sections sections={sections} />
    </>
  );
}
