import contentHeroFragment from '@lib/fragments/content-hero-fragment';
import { localizationsFields } from '@lib/fragments/localizations-fragment';
import {
  sectionsFields,
  sectionsFragment,
} from '@lib/fragments/sections-fragment';
import { seoFields } from '@lib/fragments/seo-fragment';
import gql from 'graphql-tag';

const NEWS_OVERVIEW_PAGE_QUERY = gql`
  query NEWS_OVERVIEW_PAGE_QUERY($locale: String) {
    NewsOverviewPage(locale: $locale) {
      typename: __typename
      id: _id
      title
      hero {
        ...contentHeroFields
      }
      ${localizationsFields}
      ${sectionsFields}
      ${seoFields}
    }
  }
  ${contentHeroFragment}
  ${sectionsFragment}
`;

export default NEWS_OVERVIEW_PAGE_QUERY;
