import gql from 'graphql-tag';

const SOLUTIONS_FILTER_QUERY = gql`
  query SOLUTIONS_FILTER_QUERY($locale: String) {
    SolutionPages(limit: 100, locale: $locale, sort: title_ASC) {
      items {
        typename: __typename
        id: _id
        title
      }
    }
  }
`;

export default SOLUTIONS_FILTER_QUERY;
