import { Testimonial } from '@type-declarations/brand';
import clsx from 'clsx';
import { motion, useScroll, useTransform } from 'framer-motion';
import Image from 'next/image';
import { useRef } from 'react';

import styles from './TestimonialsSection.module.scss';

interface Props {
  testimonials: Testimonial[];
}

export default function TestimonialsSection({ testimonials }: Props) {
  /* Track scroll */
  const containerRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start start', 'end end'],
  });

  // List animation values
  const BREAK_POINT = 1 / (testimonials.length + 2);
  const xList = useTransform(
    scrollYProgress,
    [0, BREAK_POINT, 1 - BREAK_POINT, 1],
    ['30%', '0%', '0%', '-30%'],
  );
  const opacityList = useTransform(
    scrollYProgress,
    [0, BREAK_POINT, 1 - BREAK_POINT, 1],
    [0, 1, 1, 0],
  );

  /* Horizontal scroll of cards */
  const xCards = useTransform(
    scrollYProgress,
    [BREAK_POINT, 1 - BREAK_POINT],
    ['0%', `-${(testimonials.length - 1) * 100}%`],
  );

  const height = `${(testimonials.length + 1) * 100}vh`;

  return (
    <section
      style={{ minHeight: height }}
      ref={containerRef}
      className={styles.section}
    >
      <div className={styles.stickyContainer}>
        <div className="u-container-width">
          <motion.ul
            style={{
              x: xList,
              opacity: opacityList,
            }}
            className={clsx(styles.list, 'u-list-clean u-mb-0')}
          >
            {testimonials?.map(({ name, id, image, job, text }) => (
              <motion.li style={{ x: xCards }} className={styles.item} key={id}>
                <Image
                  src={image.mobileUrl}
                  alt={name}
                  width={305}
                  height={257}
                  placeholder="blur"
                  blurDataURL={image.placeholder}
                  className={clsx(styles.image, styles.mobile)}
                />
                <Image
                  src={image.url}
                  alt={name}
                  width={image.width}
                  height={image.height}
                  placeholder="blur"
                  blurDataURL={image.placeholder}
                  className={styles.image}
                />
                <div className={styles.content}>
                  <p className={clsx(styles.name, 'u-mb-0')}>{name}</p>
                  <p className={styles.job}>{job}</p>

                  <p className={clsx(styles.text, 'u-mb-0')}>
                    &quot;{text}&quot;
                  </p>
                </div>
              </motion.li>
            ))}
          </motion.ul>
        </div>
      </div>
    </section>
  );
}
