import gql from 'graphql-tag';

import { contentSlideFields } from '../content-slide-fragment';

const contentSliderSectionFragment = gql`
  fragment contentSliderSectionFields on ContentSliderSection {
    typename: __typename
    id: _id
    title
    text
    cta {
      ...ctaFields
    }
    content {
      ${contentSlideFields}
    }
  }
`;

export default contentSliderSectionFragment;
