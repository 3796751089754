import { ROUTES } from '@constants/constants';
import useLocale from '@hooks/useLocale';
import { DetailPage, Page } from '@type-declarations/page';

import useTranslations from './useTranslations';

export interface Breadcrumb {
  title: string;
  slug: string;
}

export default function useBreadcrumbs(page: Page) {
  const locale = useLocale();
  const t = useTranslations();

  if (
    !page?.title ||
    page.typename === 'HomePage' ||
    page.typename === 'ThankYouPage'
  ) {
    return { breadcrumbs: [] };
  }

  const homePage = {
    title: t.breadcrumbs.home,
    slug: `/${locale}`,
  };
  const newsOverviewPage = {
    title: t.breadcrumbs.news,
    slug: `/${locale}/${ROUTES.NewsOverviewPage[locale]}`,
  };
  const casesOverviewPage = {
    title: t.breadcrumbs.cases,
    slug: `/${locale}/${ROUTES.CasesOverviewPage[locale]}`,
  };
  const solutionsOverviewPage = {
    title: t.breadcrumbs.solutions,
    slug: `/${locale}/${ROUTES.SolutionsOverviewPage[locale]}`,
  };
  const vacanciesOverviewPage = {
    title: t.breadcrumbs.vacancies,
    slug: `/${locale}/${ROUTES.VacanciesOverviewPage[locale]}`,
  };
  const eventsOverviewPage = {
    title: t.breadcrumbs.events,
    slug: `/${locale}/${ROUTES.EventsOverviewPage[locale]}`,
  };
  const companiesOverviewPage = {
    title: t.breadcrumbs.companies,
    slug: `/${locale}/${ROUTES.CompaniesOverviewPage[locale]}`,
  };

  switch (page.typename) {
    case 'NewsOverviewPage':
      return { breadcrumbs: [homePage, newsOverviewPage] };
    case 'CasesOverviewPage':
      return { breadcrumbs: [homePage, casesOverviewPage] };
    case 'SolutionsOverviewPage':
      return { breadcrumbs: [homePage, solutionsOverviewPage] };
    case 'EventsOverviewPage':
      return { breadcrumbs: [homePage, eventsOverviewPage] };
    case 'VacanciesOverviewPage':
      return { breadcrumbs: [homePage, vacanciesOverviewPage] };
    case 'CompaniesOverviewPage':
      return { breadcrumbs: [homePage, companiesOverviewPage] };
    default:
  }

  let breadcrumbs: Breadcrumb[] = [];

  const setSlug = (item: DetailPage): string => {
    const { slug } = item;
    const baseUrls = {
      ContentPage: '',
      NewsPage: ROUTES.NewsOverviewPage[locale],
      CasePage: ROUTES.CasesOverviewPage[locale],
      CompanyPage: ROUTES.CompaniesOverviewPage[locale],
      SolutionPage: ROUTES.SolutionsOverviewPage[locale],
      EventPage: ROUTES.EventsOverviewPage[locale],
      VacancyPage: ROUTES.VacanciesOverviewPage[locale],
      VacancyApplyPage: ROUTES.VacanciesOverviewPage[locale],
      ThankYouPage: ROUTES.VacanciesOverviewPage[locale],
      BrandPage: '',
    };

    if (item.typename === 'ContentPage' && item.parent?.length) {
      // Call the function again with the parent page to get the full slug
      return `${setSlug(item.parent[0])}/${slug}`;
    }

    return `/${locale}${baseUrls[item.typename] ? `/${baseUrls[item.typename]}` : ''}/${slug}`;
  };
  // The middle part of the breadcrumbs will be filled with the parent pages,
  // This is a function that will call itself until no longer needed.
  const setBreadcrumbItem = (item: DetailPage) => {
    if (item.typename === 'ContentPage' && item.parent?.length) {
      // Add the parent page to the breadcrumbs
      breadcrumbs = [
        { title: item.parent[0].title, slug: setSlug(item.parent[0]) },
        ...breadcrumbs,
      ];
      setBreadcrumbItem(...item.parent);
      return;
    }
    // News has no parent page other than the news overviewpage
    if (item.typename === 'NewsPage') {
      breadcrumbs = [newsOverviewPage, ...breadcrumbs];
      return;
    }
    if (item.typename === 'CasePage') {
      breadcrumbs = [casesOverviewPage, ...breadcrumbs];
      return;
    }
    if (item.typename === 'CompanyPage') {
      breadcrumbs = [companiesOverviewPage, ...breadcrumbs];
      return;
    }
    if (item.typename === 'SolutionPage') {
      breadcrumbs = [solutionsOverviewPage, ...breadcrumbs];
    }
    if (item.typename === 'VacancyPage') {
      breadcrumbs = [vacanciesOverviewPage, ...breadcrumbs];
    }
    if (item.typename === 'VacancyApplyPage') {
      breadcrumbs = [vacanciesOverviewPage, ...breadcrumbs];
    }
    if (item.typename === 'EventPage') {
      breadcrumbs = [eventsOverviewPage, ...breadcrumbs];
    }
  };
  setBreadcrumbItem(page);
  // The first breadcrumb will always be home,
  // the last breadcrumb will always be the current page
  breadcrumbs = [
    homePage,
    ...breadcrumbs,
    {
      title: page.title,
      slug: setSlug(page),
    },
  ];

  return {
    breadcrumbs,
  };
}
