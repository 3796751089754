import RichText from '@atoms/RichText/RichText';
import { DEFAULT_FOCUS_POINT, FOCUS_POINT } from '@constants/constants';
import { DetailHero as Props } from '@type-declarations/detailHero';
import clsx from 'clsx';
import Image from 'next/legacy/image';

import styles from './DetailHero.module.scss';

export default function DetailHero({
  title,
  text,
  image,
  focusPoint,
  children,
}: Props) {
  return (
    <div className={clsx('u-container', styles.hero)}>
      <div className={clsx('u-container', styles.contentContainer)}>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.content}>
          <RichText
            text={text}
            className={clsx(children && styles.hasChildren)}
          />
          {children}
        </div>
      </div>
      {image && (
        <div className={styles.imageWrapper}>
          <div className={styles.imageContainer}>
            <Image
              src={image.url}
              placeholder="blur"
              blurDataURL={image.placeholder}
              alt={image.description || ''}
              layout="fill"
              objectFit="cover"
              objectPosition={FOCUS_POINT[focusPoint || DEFAULT_FOCUS_POINT]}
              priority
            />
          </div>
        </div>
      )}
    </div>
  );
}
