import useMatterjs from '@hooks/useMatterjs';
import { useIsGreaterThanMd } from '@hooks/useMediaQuery';
import AnchrLogo from '@svg/logo.svg';
import getMediaSize from '@utils/getMediaSize';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import {
  Bodies,
  Body,
  Constraint,
  IChamferableBodyDefinition,
  Mouse,
  MouseConstraint,
} from 'matter-js';
import { useEffect, useRef, useState } from 'react';

import styles from './BrandLogo.module.scss';

const BORDER_SIZE = 400;

interface Props {
  hasScrolled: boolean;
  toBottom: boolean;
}

export default function BrandLogo({ hasScrolled, toBottom }: Props) {
  const isGreaterThanMD = useIsGreaterThanMd();
  const [isDragging, setIsDragging] = useState(false);
  const [logoPosition, setLogoPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const logoRef = useRef<HTMLDivElement>(null);
  const logoBody = useRef<Body>();
  const borders = useRef<Body[]>();
  const constraint = useRef<Constraint>();
  const mouseConstraint = useRef<MouseConstraint>();

  const {
    startWorld,
    addToWorld,
    removeFromWorld,
    engine,
    endWorld,
    addEngineEventListener,
    removeEngineEventListener,
  } = useMatterjs({
    renderOptions: {
      background: 'transparent',
    },
    engineOptions: {
      gravity: { x: 0, y: 0 },
    },
  });

  /* Start world */
  useEffect(() => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    logoBody.current = Bodies.rectangle(
      screenWidth / 2,
      screenHeight / 2,
      logoRef.current!.clientWidth,
      logoRef.current!.clientHeight,
      {
        isStatic: false,
        restitution: 0.5,
        friction: 0.1,
        inertia: Infinity, // prevent rotation
      },
    );

    const borderOptions: IChamferableBodyDefinition = {
      friction: 0,
      frictionStatic: 0,
      isStatic: true,
      render: { visible: false },
    };
    borders.current = [
      // top
      Bodies.rectangle(
        screenWidth / 2,
        -BORDER_SIZE / 2,
        screenWidth + 2 * BORDER_SIZE,
        BORDER_SIZE,
        borderOptions,
      ),
      // right
      Bodies.rectangle(
        screenWidth + BORDER_SIZE / 2,
        screenHeight / 2,
        BORDER_SIZE,
        screenHeight + 2 * BORDER_SIZE,
        borderOptions,
      ),
      // bottom
      Bodies.rectangle(
        screenWidth / 2,
        screenHeight + BORDER_SIZE / 2,
        screenWidth + 2 * BORDER_SIZE,
        BORDER_SIZE,
        { ...borderOptions, friction: 0.5 },
      ),
      // left
      Bodies.rectangle(
        -BORDER_SIZE / 2,
        screenHeight / 2,
        BORDER_SIZE,
        screenHeight + 2 * BORDER_SIZE,
        borderOptions,
      ),
    ];

    constraint.current = Constraint.create({
      pointA: { x: screenWidth / 2, y: screenHeight / 2 },
      bodyB: logoBody.current,
      stiffness: 0.01,
      length: 0,
      render: {
        visible: true,
        strokeStyle: '#000',
        type: 'line',
      },
    });

    if (isGreaterThanMD) {
      startWorld([logoBody.current, ...borders.current, constraint.current]);
    }

    return () => {
      endWorld();
    };
  }, [startWorld, engine, isGreaterThanMD, endWorld]);

  /* Update bodies on resize */
  useEffect(() => {
    const updateBodies = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      /* Update logo position */
      if (logoBody.current) {
        Body.setPosition(logoBody.current, {
          x: screenWidth / 2,
          y: screenHeight / 2,
        });
      }

      /* Update borders */
      if (borders.current) {
        Body.setPosition(borders.current[0], {
          x: screenWidth / 2,
          y: -BORDER_SIZE / 2,
        });
        Body.setPosition(borders.current[1], {
          x: screenWidth + BORDER_SIZE / 2,
          y: screenHeight / 2,
        });
        Body.setPosition(borders.current[2], {
          x: screenWidth / 2,
          y: screenHeight + BORDER_SIZE / 2,
        });
        Body.setPosition(borders.current[3], {
          x: -BORDER_SIZE / 2,
          y: screenHeight / 2,
        });
      }

      /* Update constraint */
      if (constraint.current && !hasScrolled && !toBottom) {
        constraint.current.pointA = { x: screenWidth / 2, y: screenHeight / 2 };
      }
    };

    window.addEventListener('resize', updateBodies);
    return () => {
      window.removeEventListener('resize', updateBodies);
    };
  }, [hasScrolled, startWorld, toBottom]);

  /* Update constraint */
  useEffect(() => {
    if (!constraint.current) return;

    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    if (!hasScrolled && !toBottom) {
      constraint.current.pointA = { x: screenWidth / 2, y: screenHeight / 2 };
    } else if (hasScrolled && !toBottom) {
      const ms = getMediaSize();
      let x = 5 * 4;
      if (ms === 'xs') x = 6 * 4;
      if (ms === 'md') x = 15 * 4;
      if (ms === 'lg') x = 16 * 4;
      if (ms === 'xl') x = 22 * 4;

      constraint.current.pointA = {
        x: x + logoRef.current!.clientWidth / 2,
        y: 60,
      };
      logoBody.current!.frictionAir = 1;

      if (mouseConstraint.current) {
        removeFromWorld([mouseConstraint.current]);
      }

      if (!engine.current!.world.constraints.includes(constraint.current)) {
        addToWorld([constraint.current]);

        // set gravity to 0
        engine.current!.gravity.y = 0;
      }
    } else if (toBottom) {
      // disable constraint
      logoBody.current!.frictionAir = 0.01; // default value
      removeFromWorld([constraint.current]);

      // Create the mouse constraint
      if (!mouseConstraint.current) {
        const mouse = Mouse.create(containerRef.current!);
        mouseConstraint.current = MouseConstraint.create(engine.current!, {
          mouse,
          constraint: {
            stiffness: 0.1,
            render: {
              visible: true,
              strokeStyle: '#f00',
              type: 'line',
            },
          },
        });
      }

      addToWorld([mouseConstraint.current]);

      // set gravity to default
      engine.current!.gravity.y = 1;
    }
  }, [addToWorld, engine, hasScrolled, toBottom, removeFromWorld]);

  /* Update logo based on engine body position */
  useEffect(() => {
    const updateLogoPosition = () => {
      if (!logoBody.current) return;

      if (
        logoBody.current.position.x === logoPosition.x &&
        logoBody.current.position.y === logoPosition.y
      )
        return;

      setLogoPosition({
        x: logoBody.current.position.x,
        y: logoBody.current.position.y,
      });
    };

    if (isGreaterThanMD) {
      addEngineEventListener('beforeUpdate', updateLogoPosition);
    }
    return () => {
      removeEngineEventListener('beforeUpdate', updateLogoPosition);
    };
  }, [
    addEngineEventListener,
    removeEngineEventListener,
    logoPosition,
    isGreaterThanMD,
  ]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      data-brand-logo-container
      ref={containerRef}
      className={styles.canvasContainer}
      onMouseUp={() => setIsDragging(false)}
      style={{ pointerEvents: isDragging ? 'auto' : 'none' }}
    >
      <motion.div
        ref={logoRef}
        className={clsx(
          styles.logoContainer,
          !hasScrolled && styles.center,
          hasScrolled && styles.top,
          toBottom && styles.bottom,
        )}
        transition={{ duration: 0 }}
        style={{
          // eslint-disable-next-line no-nested-ternary
          cursor: toBottom ? (isDragging ? 'grabbing' : 'grab') : 'default',
        }}
        animate={{
          x: logoPosition.x - (logoRef.current?.clientWidth || 40) / 2,
          y: logoPosition.y - (logoRef.current?.clientHeight || 40) / 2,
        }}
        onMouseDown={() => setIsDragging(true)}
      >
        <AnchrLogo
          data-brand-logo
          className={clsx(styles.logo, hasScrolled && styles.scrolled)}
        />
      </motion.div>
    </div>
  );
}
