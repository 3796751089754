import {
  ITEMS_PER_PAGE,
  ITEMS_PER_PAGE_PAST_EVENTS,
} from '@constants/constants';
import useTranslations from '@hooks/useTranslations';
import Breadcrumbs from '@molecules/Breadcrumbs/Breadcrumbs';
import ContentHero from '@organisms/ContentHero/ContentHero';
import EventGrid from '@organisms/EventGrid/EventGrid';
import Sections from '@organisms/Sections/Sections';
import type { EventsOverviewPage as Props } from '@type-declarations/page';
import { useMemo } from 'react';

export default function EventsOverviewTemplate({ page }: { page: Props }) {
  const {
    title,
    hero,
    upcomingEvents,
    upcomingEventsTotal,
    pastEvents,
    pastEventsTotal,
  } = page;
  const t = useTranslations();

  const startOfDay = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    today.setMinutes(-today.getTimezoneOffset());

    return today.toISOString();
  }, []);

  return (
    <>
      <Breadcrumbs page={page} />

      <ContentHero
        shortTitle={title}
        longTitle={hero?.title}
        text={hero?.text}
        image={hero?.image}
        focusPoint={hero?.focusPoint}
        cta={hero?.cta}
      />

      <EventGrid
        title={t.upcomingEvents}
        initItems={upcomingEvents}
        initTotal={upcomingEventsTotal}
        additionalQueryParams={{
          fromGte: startOfDay,
        }}
      />

      <EventGrid
        title={t.pastEvents}
        initItems={pastEvents}
        initTotal={pastEventsTotal}
        initLimit={ITEMS_PER_PAGE_PAST_EVENTS}
        additionalQueryParams={{
          untilLt: startOfDay,
          limit: ITEMS_PER_PAGE,
          sort: 'start_date_DESC',
        }}
      />

      <Sections sections={page.sections} />
    </>
  );
}
