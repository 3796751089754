import ErrorMessage from '@atoms/ErrorMessage/ErrorMessage';
import Icon from '@atoms/Icon/Icon';
import { Error } from '@type-declarations/error';
import clsx from 'clsx';
import { ChangeEventHandler, forwardRef } from 'react';

import styles from './CheckboxInput.module.scss';

interface Props {
  id?: string;
  name: string;
  label: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  required?: boolean;
  error?: Error;
}

const CheckboxInput = forwardRef<HTMLInputElement, Props>(
  ({ id, name, label, value, onChange, required, error }: Props, ref) => (
    <div className={styles.checkbox}>
      <input
        className={clsx(styles.input, 'u-visually-hidden')}
        type="checkbox"
        name={name}
        value={value}
        id={id || name}
        onChange={onChange}
        ref={ref}
        aria-label={label}
        required={required}
      />

      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={id || name} className={styles.labelWrapper}>
        <span className={styles.indicator}>
          <Icon icon="check" className={styles.icon} />
        </span>
        <span
          className={clsx('u-typography-body', styles.label)}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: label + (required ? ' *' : ''),
          }}
        />
      </label>

      <ErrorMessage message={error?.message} />
    </div>
  ),
);

CheckboxInput.displayName = 'CheckboxInput';

export default CheckboxInput;
