import gql from 'graphql-tag';

const textSectionFragment = gql`
  fragment textSectionFields on TextSection {
    typename: __typename
    id: _id
    title
    punchline
    text
    cta {
      ...ctaFields
    }
  }
`;

export default textSectionFragment;
