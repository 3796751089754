import gql from 'graphql-tag';

import assetFragment from '../asset-fragment';
import ctaFragment from '../cta-fragment';

const contactSectionFragment = gql`
  fragment contactSectionFields on ContactSection {
    typename: __typename
    id: _id
    contactPerson: contact_person {
      id: _id
      title
      punchline
      text
      image {
        ...assetFields
        url(preset: "Contact", format: "webp")
      }
      cta {
        ...ctaFields
      }
    }
    backgroundColor: background_color
  }
  ${assetFragment}
  ${ctaFragment}
`;

export default contactSectionFragment;
