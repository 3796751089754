import gql from 'graphql-tag';

const linkListSectionFragment = gql`
  fragment linkListSectionFields on LinkListSection {
    typename: __typename
    id: _id
    title
    text
    cta {
      ...ctaFields
    }
    list {
      ...ctaFields
    }
  }
`;

export default linkListSectionFragment;
