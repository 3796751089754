import ErrorMessage from '@atoms/ErrorMessage/ErrorMessage';
import useTranslations from '@hooks/useTranslations';
import FileUploadButton from '@molecules/Inputs/FileUpload/FileUploadButton';
import { Error } from '@type-declarations/error';
import clsx from 'clsx';

import styles from './FileUpload.module.scss';

interface Props {
  name: string;
  label: string;
  id?: string;
  disabled?: boolean;
  required?: boolean;
  maxSizeInMb?: number;
  error?: Error;
  onChange?: () => void;
}

// TODO: Support drag and drop
export default function FileUpload({
  name,
  id,
  label,
  disabled,
  required = false,
  maxSizeInMb = 3,
  error,
  onChange,
}: Props) {
  const t = useTranslations();

  return (
    <div className={styles.wrapper}>
      <p className={styles.label}>
        {label}
        {required && ' *'}
      </p>

      <div className={styles.upload}>
        <FileUploadButton
          name={name}
          id={id}
          accept=".doc,.docx,.pdf"
          disabled={disabled}
          maxSizeInMb={maxSizeInMb}
          onChange={onChange}
        />

        <p className={clsx('u-typography-paragraph-xs-light', styles.helpText)}>
          {t.fileAccept}
          <br />
          {t.maxFileSize.replace('{size}', maxSizeInMb.toString())}
        </p>
      </div>

      <ErrorMessage message={error?.message} />
    </div>
  );
}
