import cardsSectionFragment from '@lib/fragments/sections/cards-section-fragment';
import contactSectionFragment from '@lib/fragments/sections/contact-section-fragment';
import contentGridSectionFragment from '@lib/fragments/sections/content-grid-section-fragment';
import contentSliderSectionFragment from '@lib/fragments/sections/content-slider-section-fragment';
import downloadSectionFragment from '@lib/fragments/sections/download-section-fragment';
import eventSectionFragment from '@lib/fragments/sections/event-section-fragment';
import faqSectionFragment from '@lib/fragments/sections/faq-section-fragment';
import iframeSectionFragment from '@lib/fragments/sections/iframe-section-fragment';
import imageSectionFragment from '@lib/fragments/sections/image-section-fragment';
import imageSliderSectionFragment from '@lib/fragments/sections/image-slider-section-fragment';
import linkListSectionFragment from '@lib/fragments/sections/link-list-section-fragment';
import quoteSectionFragment from '@lib/fragments/sections/quote-section-fragment';
import relatedPagesSectionFragment from '@lib/fragments/sections/related-pages-section-fragment';
import textImageSectionFragment from '@lib/fragments/sections/text-image-section-fragment';
import textSectionFragment from '@lib/fragments/sections/text-section-fragment';
import videoSectionFragment from '@lib/fragments/sections/video-section-fragment';
import gql from 'graphql-tag';

import logoSliderSectionFragment from './sections/logo-slider-section-fragment';

export const sectionsFields = `
  sections {
    sections {
      ... on TextSection {
        ...textSectionFields
      }
      ... on ImageSection {
        ...imageSectionFields
      }
      ... on TextImageSection {
        ...textImageSectionFields
      }
      ... on VideoSection {
        ...videoSectionFields
      }
      ... on IframeSection {
        ...iframeSectionFields
      }
      ... on DownloadSection {
        ...downloadSectionFields
      }
      ... on ContactSection {
        ...contactSectionFields
      }
      ... on CardsSection {
        ...cardsSectionFields
      }
      ... on RelatedPagesSection {
        ...relatedPagesSectionFields
      }
      ... on ImageSliderSection {
        ...imageSliderSectionFields
      }
      ... on QuoteSection {
        ...quoteSectionFields
      }
      ... on EventSection {
        ...eventSectionFields
      }
      ... on ContentSliderSection {
        ...contentSliderSectionFields
      }
      ... on ContentGridSection {
        ...contentGridSectionFields
      }
      ... on LinkListSection {
        ...linkListSectionFields
      }
      ... on FaqSection {
        ...faqSectionFields
      }
      ... on LogoSliderSection {
        ...logoSliderSectionFields
      }
    }
  }
`;

export const sectionsFragment = gql`
  ${textSectionFragment}
  ${imageSectionFragment}
  ${textImageSectionFragment}
  ${videoSectionFragment}
  ${iframeSectionFragment}
  ${downloadSectionFragment}
  ${contactSectionFragment}
  ${cardsSectionFragment}
  ${relatedPagesSectionFragment}
  ${imageSliderSectionFragment}
  ${quoteSectionFragment}
  ${eventSectionFragment}
  ${contentSliderSectionFragment}
  ${contentGridSectionFragment}
  ${linkListSectionFragment}
  ${faqSectionFragment}
  ${logoSliderSectionFragment}
`;
