import { BRAND_STORIES_ID } from '@constants/constants';
import useMedia from '@hooks/useMedia';
import { useIsGreaterThanLg } from '@hooks/useMediaQuery';
import useTranslations from '@hooks/useTranslations';
import MuxPlayer, { MuxPlayerRefAttributes } from '@mux/mux-player-react';
import PauseIcon from '@svg/pause-audio.svg';
import PlayIcon from '@svg/play-audio.svg';
import { Image as ImageType } from '@type-declarations/media';
import clsx from 'clsx';
import { motion, useScroll, useTransform, Variants } from 'framer-motion';
import Image from 'next/legacy/image';
import { forwardRef, useEffect, useRef, useState } from 'react';

import CharacterAnimation from './CharacterAnimation';
import styles from './StoriesSection.module.scss';

const VIEW_POINT = 60;

interface Props {
  story: string;
  closingSentence: string;
  audioLabel: string;
  audioSubLabel?: string;
  audioImage: ImageType;
  audioFile: {
    playbackId: string;
  };
  scrollState: 'active' | 'after' | 'before';
}

const CLOSING_ANIMATION_DURATION_S = 0.3;

const audioVariants: Variants = {
  active: {
    opacity: 1,
    y: '0%',
  },
  before: {
    opacity: 0,
    y: '100%',
  },
  after: {
    opacity: 0,
    y: '100%',
  },
};

const StoriesSection = forwardRef<HTMLDivElement, Props>(
  (
    {
      story,
      audioLabel,
      audioSubLabel,
      audioImage,
      audioFile,
      closingSentence,
      scrollState,
    },
    ref,
  ) => {
    const [closingHeight, setClosingHeight] = useState(0);
    const [isMacBook, setIsMacBook] = useState(false);

    const greaterThanLg = useIsGreaterThanLg();
    const closingRef = useRef<HTMLParagraphElement>(null);
    const mediaRef = useRef<MuxPlayerRefAttributes>(null);
    const { isMediaPlaying, play, pause } = useMedia({ reference: mediaRef });
    const t = useTranslations();

    const { scrollYProgress } = useScroll({
      target: ref as React.RefObject<HTMLDivElement>,
      offset: [`start ${VIEW_POINT}%`, `end ${VIEW_POINT}%`],
    });

    const backgroundColor = useTransform(
      scrollYProgress,
      [0.25, 1],
      ['#8bce95', '#66de80'],
    );

    const macbookBackgroundColor = useTransform(
      scrollYProgress,
      [0.25, 1],
      ['#9FD59E', '#66de80'],
    );

    const updateClosingHeight = () => {
      if (closingRef.current) {
        setClosingHeight(closingRef.current.offsetHeight);
      }
    };

    const handlePlayMedia = () => {
      if (!isMediaPlaying) {
        play();
      } else {
        pause();
      }
    };

    useEffect(() => {
      if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(navigator.platform)) {
        setIsMacBook(true);
      }

      updateClosingHeight();
      window.addEventListener('resize', updateClosingHeight);
      return () => window.removeEventListener('resize', updateClosingHeight);
    }, []);

    return (
      <motion.section
        style={
          {
            '--background-color': backgroundColor,
            '--macbook-background-color': macbookBackgroundColor,
          } as React.CSSProperties
        }
        className={clsx(
          styles.section,
          scrollState === 'after' && styles.backgroundComplete,
          isMacBook && styles.macBook,
        )}
        id={BRAND_STORIES_ID}
      >
        <div className="u-container-width">
          <div className={styles.container}>
            <div className={styles.story}>
              <div className={styles.textContainer} ref={ref}>
                <motion.div
                  variants={{
                    up: {
                      y: -1 * closingHeight - 32,
                    },
                    down: {
                      y: 0,
                    },
                  }}
                  initial="down"
                  transition={{
                    delay: CLOSING_ANIMATION_DURATION_S / 2,
                    duration: CLOSING_ANIMATION_DURATION_S,
                    ease: 'easeInOut',
                  }}
                  animate={scrollState === 'after' ? 'up' : 'down'}
                  className={styles.text}
                >
                  {story.split('\n').map((paragraph, i) => (
                    <CharacterAnimation
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      text={paragraph}
                      viewPoint={VIEW_POINT}
                    />
                  ))}
                </motion.div>
                <motion.p
                  animate={scrollState === 'after' ? 'visible' : 'hidden'}
                  initial="hidden"
                  variants={{
                    visible: {
                      opacity: 1,
                      pointerEvents: 'auto',
                    },
                    hidden: {
                      opacity: 0,
                      pointerEvents: 'none',
                    },
                  }}
                  transition={{
                    delay:
                      scrollState === 'after'
                        ? CLOSING_ANIMATION_DURATION_S
                        : 0,
                    duration: CLOSING_ANIMATION_DURATION_S,
                    ease: 'easeInOut',
                  }}
                  ref={closingRef}
                  className={clsx(styles.closing, 'u-mb-0')}
                >
                  {closingSentence}
                </motion.p>
              </div>
            </div>
            <motion.div
              animate={greaterThanLg ? scrollState : 'active'}
              transition={{ duration: 0.2, ease: 'easeInOut' }}
              variants={audioVariants}
              initial="before"
              className={styles.audioContainer}
            >
              <p className={clsx(styles.label, 'u-mb-0')}>{audioLabel}</p>
              {audioSubLabel && (
                <p className={clsx(styles.sub, 'u-mb-0')}>{audioSubLabel}</p>
              )}
              <div className={styles.circles}>
                <div className={styles.authorImageWrapper}>
                  <Image
                    src={audioImage.url}
                    placeholder="blur"
                    blurDataURL={audioImage.placeholder}
                    layout="fill"
                    alt=""
                  />
                </div>
                <button
                  type="button"
                  onClick={handlePlayMedia}
                  className={styles.playButton}
                >
                  <span className="u-visually-hidden">
                    {isMediaPlaying ? t.pauseAudio : t.playAudio}
                  </span>
                  {isMediaPlaying ? (
                    <PauseIcon className={styles.pauseIcon} />
                  ) : (
                    <PlayIcon className={styles.playIcon} />
                  )}
                </button>
                <MuxPlayer
                  playbackId={audioFile?.playbackId}
                  ref={mediaRef}
                  className="u-visually-hidden"
                />
              </div>
            </motion.div>
          </div>
        </div>
      </motion.section>
    );
  },
);

StoriesSection.displayName = 'StoriesSection';

export default StoriesSection;
