import useTranslations from '@hooks/useTranslations';
import VacancySpecification from '@molecules/VacancySpecifications/VacancySpecification';
import { Salary } from '@type-declarations/vacancy';
import clsx from 'clsx';

import styles from './VacancySpecifications.module.scss';

interface Props {
  department: string;
  company: string;
  salary: Salary;
  location: string;
  className?: string;
  modifier?: 'narrow';
}

export default function VacancySpecifications({
  department,
  company,
  salary,
  location,
  className,
  modifier,
}: Props) {
  const t = useTranslations();

  return (
    <dl className={clsx(styles.list, className, modifier && styles[modifier])}>
      <VacancySpecification
        icon="briefcase"
        label={t.department}
        value={department}
      />
      <VacancySpecification icon="file" label={t.company} value={company} />
      {(salary.min || salary.max) && (
        <VacancySpecification
          icon="credit-card"
          label={t.salary}
          value={
            salary.min && salary.max
              ? `€ ${salary.min} - € ${salary.max}`
              : `€ ${salary.min || salary.max}`
          }
        />
      )}
      <VacancySpecification
        icon="map-pin"
        label={t.location}
        value={location}
      />
    </dl>
  );
}
