import assetFragment from '@lib/fragments/asset-fragment';
import gql from 'graphql-tag';

import { locationFields } from './location-fragment';

export const overviewInfoFragment = gql`
  fragment overviewInfoFields on OverviewInformation {
    title
    image {
      ...assetFields
      url(preset: "Overview", format: "webp")
      sliderUrl: url(preset: "Slider", format: "webp")
    }
    focusPoint: focus_point
  }
  ${assetFragment}
`;

export const solutionOverviewInfoFragment = gql`
  fragment solutionOverviewInfoFields on SolutionOverviewInformation {
    title
    text
    punchline
    image {
      ...assetFields
      url(preset: "Overview", format: "webp")
    }
    focusPoint: focus_point
  }
  ${assetFragment}
`;

export const newsItem = gql`
  fragment newsItem on NewsPage {
    typename: __typename
    id: _id
    slug: _slug
    title
    overviewInfo: overview_info {
      ...overviewInfoFields
    }
    solutions {
      title
    }
  }
  ${overviewInfoFragment}
  ${assetFragment}
`;

export const contentItem = gql`
  fragment contentItem on ContentPage {
    typename: __typename
    id: _id
    slug: _slug
    title
    overviewInfo: overview_info {
      ...overviewInfoFields
    }
    solutions {
      title
    }
  }
  ${overviewInfoFragment}
  ${assetFragment}
`;

export const solutionItem = gql`
  fragment solutionItem on SolutionPage {
    typename: __typename
    id: _id
    slug: _slug
    title
    overviewInfo: overview_info {
      ...solutionOverviewInfoFields
    }
  }
  ${solutionOverviewInfoFragment}
  ${assetFragment}
`;

export const caseItem = gql`
  fragment caseItem on CasePage {
    typename: __typename
    id: _id
    slug: _slug
    title
    overviewInfo: overview_info {
      ...overviewInfoFields
    }
    solutions {
      title
    }
  }
  ${overviewInfoFragment}
  ${assetFragment}
`;

export const eventItem = gql`
  fragment eventItem on EventPage {
    typename: __typename
    id: _id
    slug: _slug
    title
    overviewInfo: overview_info {
      ...overviewInfoFields
    }
    startDate: start_date
    online
    ${locationFields}
  }
  ${overviewInfoFragment}
  ${assetFragment}
`;

export const vacancyItem = gql`
  fragment vacancyItem on VacancyPage {
    typename: __typename
    id: _id
    slug: _slug
    title
    overviewInfo: overview_info {
      text
    }
    department
    company
    minSalary: min_salary
    maxSalary: max_salary
    location
  }
`;

export const companyItem = gql`
  fragment companyItem on CompanyPage {
    typename: __typename
    id: _id
    slug: _slug
    title
    logo {
      ...assetFields
      url(width: 400, format: "webp")
    }
    overviewInfo: overview_info {
      text
      image {
        ...assetFields
        url(preset: "Overview", format: "webp")
      }
    }
  }
  ${assetFragment}
`;
