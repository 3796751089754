import gql from 'graphql-tag';

const iframeSectionFragment = gql`
  fragment iframeSectionFields on IframeSection {
    typename: __typename
    id: _id
    title
    punchline
    text
    iframeSource: iframe_source
    iframeName: iframe_name
    iframeHeight: iframe_height
    cta {
      ...ctaFields
    }
  }
`;

export default iframeSectionFragment;
