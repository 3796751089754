import { ctaLinkFields } from '@lib/fragments/cta-fragment';
import gql from 'graphql-tag';

const imageSliderSectionFragment = gql`
  fragment imageSliderSectionFields on ImageSliderSection {
    typename: __typename
    id: _id
    title
    punchline
    text
    cta {
      ...ctaFields
    }
    selection {
      typename: __typename
      id: _id
      title
      text
      cta {
        ${ctaLinkFields}
      }
      image {
        ...assetFields
        url(preset: "Slide", format: "webp")
      }
    }
  }
`;

export default imageSliderSectionFragment;
