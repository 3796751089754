import useTranslations from '@hooks/useTranslations';
import clsx from 'clsx';

import styles from './VacancyCounter.module.scss';

interface Props {
  total: number;
}

export default function VacancyCounter({ total }: Props) {
  const t = useTranslations();

  return (
    <p
      className={clsx(
        styles.counter,
        'u-typography-heading-3 u-color-blue-900',
      )}
    >
      {total === 0 && t.noVacancies}
      {total === 1 && t.oneVacancy}
      {total > 1 && t.allXVacancies.replace('{total}', total.toString())}
    </p>
  );
}
