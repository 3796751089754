import Button from '@atoms/Button/Button';
import Icon from '@atoms/Icon/Icon';
import { ROUTES } from '@constants/constants';
import useLocale from '@hooks/useLocale';
import useTranslations from '@hooks/useTranslations';
import { Salary } from '@type-declarations/vacancy';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import styles from './ApplyCta.module.scss';

interface Props {
  vacancy: string;
  department: string;
  company: string;
  salary: Salary;
  location: string;
}

export default function ApplyCta({
  vacancy,
  department,
  company,
  salary,
  location,
}: Props) {
  const router = useRouter();
  const locale = useLocale();
  const t = useTranslations();

  const [showStickyBar, setShowStickyBar] = useState(false);

  const { ref: staticCtaRef } = useInView({
    onChange: (inView, entry) => {
      const distanceFromTop = entry.target.getBoundingClientRect().top;
      if (distanceFromTop > 0) return;

      setShowStickyBar(!inView);
    },
  });

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY === 0) {
        setShowStickyBar(false);
      }
    };

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  const applyUrl = `${router.asPath}/${ROUTES.VacancyApplyPage[locale]}`;

  const handleScroll = () => window.scrollTo(0, 0);

  return (
    <>
      <Button
        ref={staticCtaRef}
        href={applyUrl}
        label={t.apply}
        className={styles.staticCta}
        stretch
      />

      <div
        className={clsx(
          styles.fixedBar,
          'u-container-width',
          !showStickyBar && styles.fixedBarHidden,
        )}
      >
        <div className={clsx(styles.fixedBarItem, styles.content)}>
          <div className="u-visually-hidden@until-md">
            <p className="u-typography-heading-4 u-mb-1">{vacancy}</p>

            <ul className={clsx(styles.details, 'u-list-clean')}>
              <li className={styles.detail}>{department}</li>
              <li className={styles.detail}>{company}</li>
              {(salary.min || salary.max) && (
                <li className={styles.detail}>
                  {salary.min && salary.max
                    ? `€ ${salary.min} - € ${salary.max}`
                    : `€ ${salary.min || salary.max}`}
                </li>
              )}
              <li className={styles.detail}>{location}</li>
            </ul>
          </div>

          <Button
            href={applyUrl}
            label={t.applyNow}
            className={styles.fixedCta}
          />
        </div>

        <button
          type="button"
          className={clsx(styles.fixedBarItem, styles.scrollToTop)}
          aria-label={t.scrollToTop}
          title={t.scrollToTop}
          onClick={handleScroll}
        >
          <Icon icon="arrow-up" />
        </button>
      </div>
    </>
  );
}
