import { caseItem } from '@lib/fragments/overview-fragment';
import { SolutionsQueryType } from '@type-declarations/prepr';
import gql from 'graphql-tag';

const CASE_PAGES_QUERY = (solutions?: SolutionsQueryType) => gql`
  query CASE_PAGES_QUERY(
    $limit: Int
    $skip: Int
    $locale: String
    ${solutions?.length ? '$solutions: [String]' : ''}
  ) {
    CasePages(
      limit: $limit
      skip: $skip
      locale: $locale
      sort: publish_on_DESC
      ${solutions?.length ? 'where: { solutions: { _id_any: $solutions } }' : ''}
      ${solutions === null ? 'where: { solutions: null }' : ''}
    ) {
      items {
        ...caseItem
      }
      total
    }
  }
  ${caseItem}
`;

export default CASE_PAGES_QUERY;
