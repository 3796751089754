import gql from 'graphql-tag';

const faqSectionFragment = gql`
  fragment faqSectionFields on FaqSection {
    typename: __typename
    title
    text
    cta {
      ...ctaFields
    }
    questions {
      id: _id
      question
      answer
    }
    firstQuestionOpen: first_question_open
  }
`;

export default faqSectionFragment;
