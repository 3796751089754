import Tags from '@atoms/Tags/Tags';
import useTranslations from '@hooks/useTranslations';
import Breadcrumbs from '@molecules/Breadcrumbs/Breadcrumbs';
import Section from '@molecules/Section/Section';
import ContentHero from '@organisms/ContentHero/ContentHero';
import RelatedPagesSection from '@organisms/RelatedPagesSection/RelatedPagesSection';
import Sections from '@organisms/Sections/Sections';
import type { SolutionsOverviewPage as Props } from '@type-declarations/page';

export default function SolutionsOverviewTemplate({ page }: { page: Props }) {
  const { title, hero, items, casesPerSolution } = page;
  const t = useTranslations();

  return (
    <>
      <Breadcrumbs page={page} />

      <ContentHero
        shortTitle={title}
        longTitle={hero?.title}
        text={hero?.text}
        image={hero?.image}
        focusPoint={hero?.focusPoint}
        cta={hero?.cta}
      />

      <Section noPadding>
        <Tags tags={items} variant="secondary" Type="a" />
      </Section>

      <Section fullWidth>
        {items.map((item, index) => (
          <RelatedPagesSection
            key={item.id}
            id={item.slug}
            title={item.title}
            punchline={item.overviewInfo?.punchline}
            text={item.overviewInfo?.text}
            selection={casesPerSolution[index].items}
            typename="RelatedPagesSection"
            noBorderTop={index !== 0}
            cta={{
              id: `cta-${item.id}`,
              link: [
                {
                  id: item.id,
                  typename: 'InternalLink',
                  title: t.readAll,
                  internalLink: {
                    page: [
                      {
                        id: item.id,
                        typename: 'SolutionPage',
                        slug: item.slug,
                      },
                    ],
                  },
                },
              ],
            }}
          />
        ))}
      </Section>

      <Sections sections={page.sections} />
    </>
  );
}
