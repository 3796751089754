import assetFragment from '@lib/fragments/asset-fragment';
import detailHeroFragment from '@lib/fragments/detail-hero-fragment';
import { localizationsFields } from '@lib/fragments/localizations-fragment';
import { locationFields } from '@lib/fragments/location-fragment';
import {
  sectionsFields,
  sectionsFragment,
} from '@lib/fragments/sections-fragment';
import { seoFields } from '@lib/fragments/seo-fragment';
import gql from 'graphql-tag';

const EVENT_PAGE_QUERY = gql`
  query EVENT_PAGE_QUERY($slug: String, $locale: String) {
    EventPage(slug: $slug, locale: $locale) {
      typename: __typename
      id: _id
      slug: _slug
      publishOn: _publish_on
      title
      hero {
        ...detailHeroFields
      }
      iframeSource: iframe_source
      iframeName: iframe_name
      iframeHeight: iframe_height
      iframeTitle: iframe_title
      iframePunchline: iframe_punchline
      iframeText: iframe_text
      startDate: start_date
      endDate: end_date
      online
      language
      price
      targetAudience: target_audience
      ${localizationsFields}
      ${sectionsFields}
      ${seoFields}
      ${locationFields}
    }
  }
  ${detailHeroFragment}
  ${assetFragment}
  ${sectionsFragment}
`;

export default EVENT_PAGE_QUERY;
