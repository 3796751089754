import { ROUTES } from '@constants/constants';
import useLocale from '@hooks/useLocale';
import Breadcrumbs from '@molecules/Breadcrumbs/Breadcrumbs';
import ContentHero from '@organisms/ContentHero/ContentHero';
import CookieDeclarationSection from '@organisms/CookieDeclarationSection/CookieDeclarationSection';
import Sections from '@organisms/Sections/Sections';
import { ContentPage as Props } from '@type-declarations/page';
import Head from 'next/head';
import { useRouter } from 'next/router';

function ContentTemplate({ page }: { page: Props }) {
  const { title, hero, hidePage } = page;
  const locale = useLocale();
  const router = useRouter();

  return (
    <>
      {hidePage && (
        <Head>
          <meta name="robots" content="noindex, nofollow" />
        </Head>
      )}

      <Breadcrumbs page={page} />

      <ContentHero
        shortTitle={title}
        longTitle={hero?.title}
        text={hero?.text}
        image={hero?.image}
        focusPoint={hero?.focusPoint}
        cta={hero?.cta}
      />

      <Sections sections={page.sections} />

      {router.asPath === `/${ROUTES.CookieDeclarationPage[locale]}` && (
        <CookieDeclarationSection />
      )}
    </>
  );
}

export default ContentTemplate;
