import { motion, MotionValue, useScroll, useTransform } from 'framer-motion';
import { useRef } from 'react';

import styles from './StoriesSection.module.scss';

interface CharProps {
  children: string;
  progress: MotionValue<number>;
  range: number[];
}

function Char({ children, progress, range }: CharProps) {
  const opacity = useTransform(progress, range, [0.2, 1]);
  return (
    <span>
      <motion.span style={{ opacity }}>{children}</motion.span>
    </span>
  );
}
interface WordProps {
  progress: MotionValue<number>;
  range: number[];
  children: string;
}

function Word({ children, progress, range }: WordProps) {
  const amount = range[1] - range[0];
  const step = amount / children.length;
  const characters: string[] = children.split('');

  return (
    <span className={styles.word}>
      {characters.map((char, i) => {
        const start = range[0] + i * step;
        const end = range[0] + (i + 1) * step;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Char key={`c_${i}`} progress={progress} range={[start, end]}>
            {char}
          </Char>
        );
      })}
    </span>
  );
}

interface ParagraphProps {
  text: string;
  viewPoint: number;
}

export default function CharacterAnimation({
  text,
  viewPoint,
}: ParagraphProps) {
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: [`start ${viewPoint}%`, `end ${viewPoint}%`],
  });

  const words: string[] = text.split(' ');
  return (
    <p ref={container} className={styles.paragraph}>
      {words.map((word, i) => {
        const start = i / words.length;
        const end = start + 1 / words.length;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Word key={i} progress={scrollYProgress} range={[start, end]}>
            {word}
          </Word>
        );
      })}
    </p>
  );
}
