import Icon, { IconType } from '@atoms/Icon/Icon';

import styles from './VacancySpecification.module.scss';

interface Props {
  icon: IconType;
  label: string;
  value: string;
}

export default function VacancySpecification({ icon, label, value }: Props) {
  return (
    <div className={styles.wrapper}>
      <Icon className={styles.icon} icon={icon} />
      <dt className="u-visually-hidden">{label}</dt>
      <dd className={styles.value}>{value}</dd>
    </div>
  );
}
