import {
  eventItem,
  overviewInfoFragment,
} from '@lib/fragments/overview-fragment';
import gql from 'graphql-tag';

import assetFragment from '../asset-fragment';

const eventSectionFragment = gql`
  fragment eventSectionFields on EventSection {
    typename: __typename
    id: _id
    title
    punchline
    text
    events {
      ...eventItem
    }
    cta {
      ...ctaFields
    }
  }
  ${assetFragment}
  ${overviewInfoFragment}
  ${eventItem}
`;

export default eventSectionFragment;
