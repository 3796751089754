import gql from 'graphql-tag';

import assetFragment from './asset-fragment';

const detailHeroFragment = gql`
  fragment detailHeroFields on DetailHero {
    title
    text
    image {
      ...assetFields
      url(width: 1920, format: "webp")
    }
    focusPoint: focus_point
  }
  ${assetFragment}
`;

export default detailHeroFragment;
