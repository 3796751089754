import CtaButton from '@molecules/CtaButton/CtaButton';
import { Cta as CtaProps } from '@type-declarations/cta';
import { Image as ImageProps } from '@type-declarations/media';
import clsx from 'clsx';
import Image from 'next/legacy/image';

import styles from './PartnershipItem.module.scss';

interface Props {
  title: string;
  image: ImageProps;
  cta: CtaProps;
}

export default function PartnershipItem({ title, image, cta }: Props) {
  return (
    <div className={styles.item}>
      <div className={styles.content}>
        <div className={styles.imageContainer}>
          <Image
            src={image.url}
            placeholder="blur"
            blurDataURL={image.placeholder}
            alt={image.description || ''}
            width={192}
            height={192}
            layout="fixed"
            className={styles.image}
          />
        </div>

        <h3 className={styles.title}>{title}</h3>

        {cta && (
          <CtaButton
            cta={cta}
            className={clsx(styles.cta, 'u-umbrella-link')}
            variant="secondary"
          />
        )}

        <span className={styles.cutoutTopLeft} aria-hidden />
        <span className={styles.cutoutTopRight} aria-hidden />
        <span className={styles.cutoutBottomLeft} aria-hidden />
        <span className={styles.cutoutBottomRight} aria-hidden />
      </div>
    </div>
  );
}
