import Button from '@atoms/Button/Button';
import ErrorMessage from '@atoms/ErrorMessage/ErrorMessage';
import Fieldset from '@atoms/Fieldset/Fieldset';
import Icon from '@atoms/Icon/Icon';
import { ROUTES } from '@constants/constants';
import { useFormErrors } from '@hooks/useErrors';
import useLocale from '@hooks/useLocale';
import useTranslations from '@hooks/useTranslations';
import CheckboxInput from '@molecules/Inputs/CheckboxInput';
import FileUpload from '@molecules/Inputs/FileUpload/FileUpload';
import TextInput from '@molecules/Inputs/TextInput';
import { toLowerCamelCase } from '@utils/formatString';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { FormEvent, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ZodIssue } from 'zod';

import styles from './ApplicationForm.module.scss';

interface Props {
  vacancy: string;
  company: string;
}

export default function ApplicationForm({ vacancy, company }: Props) {
  const router = useRouter();

  const locale = useLocale();
  const t = useTranslations();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [showMotivationTextArea, setShowMotivationTextArea] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const { setErrors, getFirstError, clearErrors } = useFormErrors();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isLoading) return;

    if (!executeRecaptcha) {
      setErrors([{ key: 'global', message: t.recaptchaLoadingError }]);
      return;
    }

    setIsLoading(true);

    const formData = new FormData(e.currentTarget);

    formData.set('locale', locale);
    formData.set('vacancyName', vacancy);

    const recaptchaToken = await executeRecaptcha();
    formData.set('recaptchaToken', recaptchaToken);

    const response = await fetch('/api/apply', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      if (response.status === 422) {
        setErrors(
          (await response.json()).errors.map((error: ZodIssue) => ({
            key: error.path[0],
            message:
              (t as { [key: string]: unknown })[
                `${toLowerCamelCase(error.message)}`
              ] || error.message,
          })),
        );
      } else {
        const error = await response.json();

        if (error.message === 'recaptchaValidationError') {
          setErrors([{ key: 'global', message: t.recaptchaValidationError }]);
        } else {
          setErrors([{ key: 'global', message: t.somethingWentWrong }]);
        }
      }
    } else {
      window.dataLayer?.push({
        event: 'vacancyApplication',
        vacancyTitle: vacancy,
        companyName: company,
      });

      router.push(
        router.asPath.replace(
          ROUTES.VacancyApplyPage[locale],
          ROUTES.ThankYouPage[locale],
        ),
      );
    }

    setIsLoading(false);
  };

  const handleMotivationToggle = () => {
    setShowMotivationTextArea(!showMotivationTextArea);
    clearErrors('motivation');
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <p className={styles.introduction}>{t.applicationForm.introduction}</p>

      <Fieldset
        className={styles.fieldset}
        legend={t.applicationForm.fieldSets.personalInformation}
      >
        <TextInput
          label={t.applicationForm.fields.firstName.label}
          name="firstName"
          required
          placeholder={t.applicationForm.fields.firstName.placeholder}
          error={getFirstError('firstName')}
          onChange={() => clearErrors('firstName')}
        />

        <TextInput
          label={t.applicationForm.fields.lastName.label}
          name="lastName"
          required
          placeholder={t.applicationForm.fields.lastName.placeholder}
          error={getFirstError('lastName')}
          onChange={() => clearErrors('lastName')}
        />

        <TextInput
          type="email"
          label={t.applicationForm.fields.email.label}
          name="emailAddress"
          required
          placeholder={t.applicationForm.fields.email.placeholder}
          error={getFirstError('emailAddress')}
          onChange={() => clearErrors('emailAddress')}
        />

        <TextInput
          label={t.applicationForm.fields.phone.label}
          name="phoneNumber"
          placeholder={t.applicationForm.fields.phone.placeholder}
          error={getFirstError('phoneNumber')}
          onChange={() => clearErrors('phoneNumber')}
        >
          <p
            className={clsx(
              'u-typography-paragraph-xs-light',
              styles.explanation,
            )}
          >
            {t.applicationForm.fields.phone.explanation}
          </p>
        </TextInput>
      </Fieldset>

      <Fieldset
        className={styles.fieldset}
        legend={t.applicationForm.fieldSets.cv}
      >
        <FileUpload
          label={t.applicationForm.fields.cv.label}
          name="cv"
          required
          error={getFirstError('cv')}
          onChange={() => clearErrors('cv')}
        />
      </Fieldset>

      <Fieldset
        className={styles.fieldset}
        legend={t.applicationForm.fieldSets.motivation}
      >
        {!showMotivationTextArea ? (
          <FileUpload
            label={t.applicationForm.fields.motivation.label.replace(
              '{company}',
              company,
            )}
            name="motivation"
            error={getFirstError('motivation')}
            onChange={() => clearErrors('motivation')}
          />
        ) : (
          <TextInput
            label={t.applicationForm.fields.motivation.label.replace(
              '{company}',
              company,
            )}
            name="motivation"
            type="textarea"
            placeholder={t.applicationForm.fields.motivation.placeholder}
            error={getFirstError('motivation')}
            onChange={() => clearErrors('motivation')}
          />
        )}

        <button
          type="button"
          className={styles.toggleMotivation}
          onClick={handleMotivationToggle}
        >
          <Icon
            icon={showMotivationTextArea ? 'upload' : 'edit'}
            className={styles.toggleIcon}
          />{' '}
          {!showMotivationTextArea
            ? t.applicationForm.fields.motivation.showTextAreaText
            : t.applicationForm.fields.motivation.hideTextAreaText}
        </button>
      </Fieldset>

      <Fieldset
        className={styles.fieldset}
        legend={t.applicationForm.fieldSets.comment}
      >
        <TextInput
          label={t.applicationForm.fields.comment.label}
          name="comment"
          type="textarea"
          placeholder={t.applicationForm.fields.comment.placeholder}
          error={getFirstError('comment')}
          onChange={() => clearErrors('comment')}
        />
      </Fieldset>

      <Fieldset
        className={styles.fieldset}
        legend={t.applicationForm.fieldSets.termsAndConditions}
      >
        <CheckboxInput
          name="termsAndConditions"
          label={t.applicationForm.fields.termsAndConditions.label}
          value="true"
          required
          error={getFirstError('termsAndConditions')}
          onChange={() => clearErrors('termsAndConditions')}
        />
      </Fieldset>

      <Button
        label={t.applicationForm.submit}
        type="submit"
        className={styles.submit}
        loading={isLoading}
      />

      <ErrorMessage message={getFirstError('global')?.message} />
    </form>
  );
}
