import useTranslations from '@hooks/useTranslations';
import clsx from 'clsx';
import { ChangeEvent, InputHTMLAttributes, useState } from 'react';

import styles from './FileUploadButton.module.scss';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'name'> {
  maxSizeInMb: number;
  name: string; // Name is required
  onChange?: () => void;
}

const mbInBytes = 1048576;

// TODO: Support drag and drop
export default function FileUploadButton({
  maxSizeInMb = 3,
  disabled,
  accept,
  name,
  id,
  onChange,
}: Props) {
  const t = useTranslations();

  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const uploadedFile = e.target.files?.length ? e.target.files[0] : null;

    if (
      maxSizeInMb &&
      uploadedFile &&
      uploadedFile.size > maxSizeInMb * mbInBytes
    ) {
      setError(t.fileToLarge);
      return;
    }

    setFile(uploadedFile);
    setError(null);

    if (onChange) {
      onChange();
    }
  }

  return (
    <>
      <input
        className={clsx('u-visually-hidden', styles.input)}
        onChange={handleChange}
        disabled={disabled}
        accept={accept}
        type="file"
        name={name}
        id={id || name}
      />

      <label htmlFor={id || name} className={styles.button}>
        <span className={styles.label}>{t.selectFile}</span>
      </label>

      {!!error && <p className={styles.error}>{error}</p>}

      {!error && file && <p className={styles.fileName}>{file.name}</p>}
    </>
  );
}
