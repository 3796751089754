import clsx from 'clsx';
import { animate } from 'framer-motion';
import { useEffect } from 'react';

import styles from './OpeningAnimation.module.scss';

export default function OpeningAnimation() {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100vh';

    animate(
      [
        /* Show greeting */
        [
          '[data-opening-greet]',
          { x: [-20, 0], y: [10, 0], opacity: [0, 1], scale: [1.1, 1] },
          { duration: 0.5 },
        ],
        /* Show introduction */
        [
          '[data-opening-intro]',
          { opacity: [0, 1], x: [5, 0] },
          { duration: 0.5, at: 1.5 },
        ],
        /* Hide text */
        ['[data-opening-text]', { opacity: 0 }, { duration: 0.5, at: 3 }],
        /* Draw line */
        ['[data-opening-line]', { width: '100%' }, { at: 3.5, duration: 0.5 }],
        // Remove line and reveal content
        ['[data-opening-line]', { opacity: 0 }, { at: 4, duration: 0.1 }],
        [
          '[data-opening-top]',
          { y: '-100%' },
          { at: 4, duration: 1, ease: 'easeIn' },
        ],
        [
          '[data-opening-bottom]',
          { y: '100%' },
          { at: 4, duration: 1, ease: 'easeIn' },
        ],
        [
          '[data-backdrop]',
          { opacity: 0 },
          { at: 4, duration: 1, ease: 'easeIn' },
        ],
        // Scale video and logo
        [
          '[data-hero-video]',
          { scale: [1.25, 1.5] },
          { at: 4, duration: 0.5, ease: 'easeOut' },
        ],
        [
          '[data-brand-logo-container]',
          { scale: [1.25, 1.5] },
          { at: 4, duration: 0.5, ease: 'easeOut' },
        ],
        // Update hero color and scale back
        [
          '[data-hero-video]',
          { scale: [1.5, 1] },
          { at: 5.5, duration: 0.5, ease: 'easeOut' },
        ],
        [
          '[data-brand-logo-container]',
          { scale: [1.5, 1] },
          { at: 5.5, duration: 0.5, ease: 'easeOut' },
        ],
        // Hide permanently
        ['[data-opening-wrapper]', { display: 'none' }, { at: 5 }],
      ],
      { delay: 0.5 },
    ).then(() => {
      document.body.style.overflow = '';
      document.body.style.height = '';
    });
  }, []);

  return (
    <div data-opening-wrapper className={styles.wrapper}>
      <div data-backdrop className={styles.backdrop} />
      <div data-opening-top className={clsx(styles.paper, styles.top)} />
      <div data-opening-bottom className={clsx(styles.paper, styles.bottom)} />
      <p data-opening-text className={clsx(styles.text, 'u-mb-0')}>
        <span data-opening-greet>Hi.</span>{' '}
        <span data-opening-intro>We like to introduce you to...</span>
      </p>
      <div data-opening-line className={styles.line} />
    </div>
  );
}
