import useLocale from '@hooks/useLocale';
import VacancyCard from '@molecules/Card/VacancyCard';
import { Vacancies } from '@type-declarations/overviewItems';
import getInternalRoute from '@utils/getInternalRoute';

interface Props {
  vacancies: Vacancies[];
}

export default function VacancyGrid({ vacancies }: Props) {
  const locale = useLocale();

  return (
    <ul className="u-grid u-grid--vacancy-card-grid u-mb-0 u-list-clean">
      {vacancies.map(vacancy => (
        <li key={vacancy.id}>
          <VacancyCard
            key={vacancy.id}
            title={vacancy.title}
            text={vacancy.overviewInfo.text}
            department={vacancy.department}
            company={vacancy.company}
            location={vacancy.location}
            salary={{ min: vacancy.minSalary, max: vacancy.maxSalary }}
            href={getInternalRoute({
              internalLink: { page: [vacancy] },
              locale,
            })}
          />
        </li>
      ))}
    </ul>
  );
}
