import { Testimonial } from '@type-declarations/brand';
import clsx from 'clsx';
import Image from 'next/image';

import styles from './MobileTestimonials.module.scss';

interface Props {
  testimonials: Testimonial[];
}

export default function MobileTestimonials({ testimonials }: Props) {
  return (
    <section className={styles.section}>
      <ul className={clsx(styles.list, 'u-mb-0 u-container-width')}>
        {testimonials.map(({ id, name, job, text, image }) => (
          <li key={id} className={styles.item}>
            <Image
              src={image.mobileUrl}
              alt={name}
              width={305}
              height={257}
              className={styles.image}
              placeholder="blur"
              blurDataURL={image.placeholder}
            />

            <p className={clsx(styles.text, 'u-mb-0')}>&quot;{text}&quot;</p>
            <p className={clsx(styles.name, 'u-mb-0')}>{name}</p>

            <p className={styles.job}>{job}</p>
          </li>
        ))}
      </ul>
    </section>
  );
}
