import useStore from '@hooks/useStore';
import Section from '@molecules/Section/Section';
import { PartnershipProps } from '@type-declarations/companiesOverview';
import { Companies } from '@type-declarations/overviewItems';
import getInternalRoute from '@utils/getInternalRoute';

import CompanyItem from './CompanyItem';
import PartnershipItem from './PartnershipItem';

interface Props extends PartnershipProps {
  items: Companies[];
}

function isEven(n: number): boolean {
  return n % 2 === 0;
}

export default function CompaniesGrid({
  items,
  lastTicketTitle,
  lastTicketImage,
  lastTicketCta,
}: Props) {
  const {
    store: { locale },
  } = useStore();

  return (
    <Section>
      <div className="u-grid u-grid--companies-grid u-grid--2@sm">
        {items.map((item, i) => (
          <CompanyItem
            key={item.id}
            title={item.title}
            logo={item.logo}
            overviewInfo={item.overviewInfo}
            href={getInternalRoute({
              internalLink: { page: [item] },
              locale,
            })}
            // Even rows should have the image on the left
            mirrored={isEven(Math.round((i + 1) / 2))}
          />
        ))}

        <PartnershipItem
          title={lastTicketTitle}
          image={lastTicketImage}
          cta={lastTicketCta}
        />
      </div>
    </Section>
  );
}
