import gql from 'graphql-tag';

const logoSliderSectionFragment = gql`
  fragment logoSliderSectionFields on LogoSliderSection {
    typename: __typename
    id: _id
    title
    punchline
    text
    cta {
      ...ctaFields
    }
    selection {
      id: _id
      title
      image {
        ...assetFields
        url(preset: "logo", format: "webp")
      }
    }
  }
`;

export default logoSliderSectionFragment;
